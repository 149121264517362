export function CadObservacao(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observação</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <textarea
              className="form-control form-control-sm border border-dark"
              name="pes_observ"
              value={props.formData?.pes_observ || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              rows="4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
