import { ApiDelete, ApiGet } from '../../../services/apiService';
import { Grid } from './Grid';

import Swal from 'sweetalert2';

export const CtrNavegacao = (props) => {
  const handleEdit = async (item) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'item',
          select: ['*'],
          where: {
            itm_item: `${item}`
          }
        },
        localStorage.getItem('sessionToken')
      );
      props.setFormData(response.data.dados.rows[0]);
      props.setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Deletar',
      html: `Deseja deletar o Item: ${id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ApiDelete('/v1/item/', { id });
        if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
          alert(response?.data?.mensagem?.msg);
          return;
        }

        alert('Item deletado com sucesso');
        props.handleSearch();
      }
    });
  };

  return (
    <Grid
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
    />
  );
};
