export function CadTipoPessoa(props) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Tipo de Pessoa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="codtpes"
              value={props.formData?.codtpes || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  codtpes: e.target.value
                });
              }}
            >
              <option defaultValue></option>
              {props.options[0]?.map((option, index) => (
                <option key={index} value={option.tip_codtpes}>
                  {option.tip_nomtpes}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
