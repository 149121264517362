export function CadTelefones(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Celular</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <div className="col-12 col-md-6">
              <input
                type="number"
                className="form-control form-control-sm border border-dark"
                name="pes_precel"
                value={props.formData?.pes_precel || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    props.handleInput(e);
                  }
                }}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control form-control-sm border border-dark"
              name="pes_telcel"
              value={props.formData?.pes_telcel || ''}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  props.handleInput(e);
                }
              }}
              style={{ marginLeft: '-1.25rem' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Fone Res.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <div className="col-12 col-md-6">
              <input
                type="number"
                className="form-control form-control-sm border border-dark"
                name="pes_preres"
                value={props.formData?.pes_preres || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    props.handleInput(e);
                  }
                }}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control form-control-sm border border-dark"
              name="pes_telres"
              value={props.formData?.pes_telres || ''}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  props.handleInput(e);
                }
              }}
              style={{ marginLeft: '-1.25rem' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Fone Com.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Ramal</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <div className="col-12 col-md-6">
              <input
                type="number"
                className="form-control form-control-sm border border-dark"
                name="pes_precom"
                value={props.formData?.pes_precom || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    props.handleInput(e);
                  }
                }}
                style={{ textTransform: 'uppercase' }}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control form-control-sm border border-dark"
              name="pes_telcom"
              value={props.formData?.pes_telcom || ''}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  props.handleInput(e);
                }
              }}
              style={{ marginLeft: '-1.25rem' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_ramal"
              value={props.formData?.pes_ramal || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Fax</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <div className="col-12 col-md-6">
              <input
                type="number"
                className="form-control form-control-sm border border-dark"
                name="pes_prere2"
                value={props.formData?.pes_prere2 || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    props.handleInput(e);
                  }
                }}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control form-control-sm border border-dark"
              name="pes_telre2"
              value={props.formData?.pes_telre2 || ''}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  props.handleInput(e);
                }
              }}
              style={{ marginLeft: '-1.25rem' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Pager</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_pager"
              value={props.formData?.pes_pager || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-8">
            <span className="col-form-label">Email</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'lowercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-8">
            <span className="col-form-label">H. Page</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_hpage"
              value={props.formData?.pes_hpage || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6"></div>
          <div className="col-6"></div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.formData?.pes_pednfe === 'S' ? true : false}
              onChange={() => {
                props.setFormData({
                  ...props.formData,
                  pes_pednfe: props.formData?.pes_pednfe === 'S' ? 'N' : 'S'
                });
              }}
              id="flexCheckDefault"
            />{' '}
            <span className="col-form-label">Informa Pedido na NFE</span>
          </div>
          <div className="col-6">
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.formData?.pes_sitpes === 'ATI' ? true : false}
              onChange={() => {
                props.setFormData({
                  ...props.formData,
                  pes_sitpes:
                    props.formData?.pes_sitpes === 'ATI' ? 'INA' : 'ATI'
                });
              }}
              id="flexCheckDefault"
            />{' '}
            <span className="col-form-label">Situação da Pessoa</span>
          </div>
        </div>
      </div>
    </div>
  );
}
