import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import {
  ApiGet,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { GridCaixa } from './liquidar/GridCaixa';
import { Liquidar } from './liquidar/Liquidar';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Relatorio } from './relatorio/Relatorio';

import { format } from 'date-fns';

export const Conta = (props) => {
  function getDateRange() {
    const today = new Date();
    const currentMonth = today.getMonth();

    const startDate = new Date(today);
    startDate.setMonth(currentMonth - 3);

    const start = startDate;
    const end = today;

    return { start, end };
  }

  const { start, end } = getDateRange();

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    datmovInicial: format(start, 'dd/MM/yyyy'),
    datmovFinal: format(end, 'dd/MM/yyyy')
  });
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState('');
  const [dataCaixa, setDataCaixa] = useState([]);
  const [formDataLiquida, setFormDataLiquida] = useState([]);

  const handleSearch = async () => {
    const tamNumcon = dataFiltro.numcon
      ? dataFiltro.numcon.padStart(7, '0')
      : '';
    const tamCodpes = dataFiltro.codpes
      ? dataFiltro.codpes.padStart(5, '0')
      : '';
    const tamCaxger = dataFiltro.caxger
      ? dataFiltro.caxger.padStart(5, '0')
      : '';
    const tamNumcax = dataFiltro.numcax
      ? dataFiltro.numcax.padStart(5, '0')
      : '';

    let datmov = null;
    let datven = null;

    const conditionDatmovInicial =
      dataFiltro.datmovInicial !== '' &&
      dataFiltro.datmovInicial !== null &&
      dataFiltro.datmovInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datmovFinal !== '' &&
      dataFiltro.datmovFinal !== null &&
      dataFiltro.datmovFinal !== undefined;

    const conditionDatvenInicial =
      dataFiltro.datvenInicial !== '' &&
      dataFiltro.datvenInicial !== null &&
      dataFiltro.datvenInicial !== undefined;
    const conditionDatvenFinal =
      dataFiltro.datvenFinal !== '' &&
      dataFiltro.datvenFinal !== null &&
      dataFiltro.datvenFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datmov = [dataFiltro.datmovInicial, dataFiltro.datmovFinal];
    }

    if (conditionDatvenInicial && conditionDatvenFinal) {
      datven = [dataFiltro.datvenInicial, dataFiltro.datvenFinal];
    }

    const body = {
      // codemp: dataFiltro.empresa || '',
      // loja: dataFiltro.loja || '',
      // undadm: dataFiltro.undadm || '',
      // numcon: tamNumcon || '',
      // docpag: dataFiltro.docpag || '',
      // tipcon: dataFiltro.tipcon || '',
      // escrit: dataFiltro.escrit || '',
      // codpes: tamCodpes || '',
      // nompes: dataFiltro.nompes || '',
      // cencus: dataFiltro.cencus || '',
      // natdesp: dataFiltro.natdesp || '',
      // recurs: dataFiltro.fontrec || '',
      // damov: datmov || [''],
      // datven: datven || [''],
      // valdps: dataFiltro.valdps || '',
      // valpag: dataFiltro.valpag || '',
      // datpag: [''],
      // caxger: tamCaxger || '',
      // numcax: tamNumcax || ''

      codigoEmpresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      unidadeAdministrativa: dataFiltro.undadm || '',
      numeroContrato: tamNumcon || '',
      documentoPagamento: dataFiltro.docpag || '',
      tipoContrato: dataFiltro.tipcon || '',
      escriturado: dataFiltro.escrit || '',
      codigoPessoa: tamCodpes || '',
      nomePessoa: dataFiltro.nompes || '',
      centroCusto: dataFiltro.cencus || '',
      naturezaDespesa: dataFiltro.natdesp || '',
      recurso: dataFiltro.fontrec || '',
      dataMovimento: datmov || '',
      dataVencimento: datven || '',
      valorDespesa: dataFiltro.valdps || '',
      valorPago: dataFiltro.valpag || '',
      dataPagamento: null,
      caixaGerado: tamCaxger || '',
      caixaLiquidado: tamNumcax || '',
      tabela: 'contas'
    };

    // const filteredBody = Object.entries(body).reduce((acc, [key, value]) => {
    //   if (Array.isArray(value) && value.length === 0) {
    //     return acc;
    //   }

    //   if (value !== '' && value !== null && value !== undefined) {
    //     acc[key] = value;
    //   }

    //   return acc;
    // }, {});

    // console.log(filteredBody);

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      {
        condition: tamNumcon,
        campo: 'Num Con',
        conteudo: tamNumcon
      },
      {
        condition: dataFiltro.docpag,
        campo: 'Doc Pag',
        conteudo: dataFiltro.docpag
      },
      {
        condition: dataFiltro.tipcon,
        campo: 'Tp Con',
        conteudo: dataFiltro.tipcon
      },
      {
        condition: dataFiltro.escrit,
        campo: 'Escrit',
        conteudo: dataFiltro.escrit
      },
      {
        condition: tamCodpes,
        campo: 'Cod Pes',
        conteudo: tamCodpes
      },
      {
        condition: dataFiltro.nompes,
        campo: 'Nom Pes',
        conteudo: dataFiltro.nompes
      },
      { condition: datmov, campo: 'Dt Mov', conteudo: datmov },
      { condition: datven, campo: 'Dt Ven', conteudo: datven },
      {
        condition: dataFiltro.valdps,
        campo: 'Val Dps',
        conteudo: dataFiltro.valdps
      },
      {
        condition: dataFiltro.valpag,
        campo: 'Val Pag',
        conteudo: dataFiltro.valpag
      }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      // const response = await ApiGet(
      //   '/contas',
      //   { contas: body },
      //   localStorage.getItem('sessionToken')
      // );
      const response = await ApiGet(
        '/v1/contas',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }
      setData(response.data.dados);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    let response = null;

    try {
      const actions = {
        insert: async () => {
          const body = {
            empresa: localStorage.getItem('sessionEmpresa'), // A empresa
            loja: localStorage.getItem('sessionLoja'), // A loja
            usuario: localStorage.getItem('usuario') || null,
            formaPagamento: formData?.cnt_forpag || null,
            tipoPagamento: formData?.cnt_tippag || null,
            codigoBanco: formData?.cnt_codban || null,
            numeroParcela: formData?.cnt_numpar || null,
            cnt_nompes: formData?.cnt_nompes || null,
            codigoContrato: formData?.cnt_codctr || null,
            codigoProjeto: formData?.cnt_codprj || null,
            numeroAprovacao: formData?.cnt_numapr || null,
            numeroNSU: formData?.cnt_nsu || null,
            tipoCartao: formData?.cnt_codcar || null,
            numeroDocumentoPagamento: formData?.cnt_docpag || null,
            naturezaDespesa: formData?.cnt_natdesp || null,
            fonteRecurso: formData?.cnt_recurs || null,
            dataMovimento: formData?.cnt_damov || null,
            dataVencimento: formData?.cnt_datven || null,
            cambio: formData?.cnt_cambio || null,
            unidadeAdministrativa: formData?.cnt_undadm || null,
            tipoConta: formData?.cnt_tipcon || null,
            numeroContrato: formData?.cnt_numcon || null,
            codigoPessoa: formData?.cnt_codpes || null,
            nomePessoa: formData?.cnt_nompes || null,
            descricaoDespesa: formData?.cnt_dscdps || null,
            valorDespesa: formData?.cnt_valdps || null,
            valorPagamento: formData?.cnt_valpag || null,
            multa: formData?.cnt_multa || null,
            juros: formData?.cnt_juros || null,
            codigoImovel: formData?.cnt_codimv || null,
            centroCusto: formData?.cnt_cencus || null,
            descricaoCentroCusto: formData?.cnt_descct || null,
            tipoPedido: formData?.cnt_tipped || null,
            numeroSerie: formData?.cnt_numser || null,
            totalParcelas: formData?.cnt_totpar || null,
            valorMoeda: formData?.cnt_valmoe || null,
            valorPagamentoCartao: formData?.cnt_vpagcar || null,
            numeroGerado: formData?.cnt_numger || null,
            tipoGeracao: formData?.cnt_tipger || null,
            desconto: formData?.cnt_descon || null,
            escrituracao: formData?.cnt_escrit || null,
            codigoConta: formData?.cnt_codcont || null,
            dataAtualizacao: formData?.cnt_datatz || null,
            horaAtualizacao: formData?.cnt_horaatz || null,
            pendente: formData?.cnt_pendente || null,
            caixaGeral: formData?.cnt_caxger || null,
            dataCobranca: formData?.cnt_dtcob || null,
            statusImpressaoBoleto: formData?.cnt_stuimpbl || null,
            statusEnvioBoleto: formData?.cnt_stuenvbl || null,
            idEcfVendaCabecalho: formData?.cnt_id_ecf_venda_cabecalho || null,
            idContasPagarReceber: formData?.cnt_id_contas_pagar_receber || null,
            valorApropriado: formData?.cnt_valaprop || null,
            codigoAdquirente: formData?.cnt_codadq || null,
            codigoBancoAdquirente: formData?.cnt_codbad || null
          };
          response = await NewApiPost(
            '/contas',
            body,
            localStorage.getItem('sessionToken')
          );
          if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
            alert(response.data.mensagem.msg);
            return;
          }
          alert('Conta registrada com sucesso');
        },
        update: async () => {
          const body = {
            codemp: formData?.cnt_codemp || '',
            loja: formData?.cnt_loja || '',
            undadm: formData?.cnt_undadm || '',
            numcon: formData?.cnt_numcon || '',
            tipcon: formData?.cnt_tipcon || '',
            codpes: formData?.cnt_codpes || '',
            nompes: formData?.cnt_nompes || '',
            dscdps: formData?.cnt_dscdps || '',
            observ: formData?.cnt_observ || '',
            escrit: formData?.cnt_escrit || '',
            forpag: formData?.cnt_forpag || '',
            tippag: formData?.cnt_tippag || '',
            cencus: formData?.cnt_cencus || '',
            descct: formData?.cnt_descct || '',
            recurs: formData?.cnt_recurs || '',
            natdesp: formData?.cnt_natdesp || '',
            codban: formData?.cnt_codban || '',
            codprj: formData?.cnt_codprj || '',
            valdps: formData?.cnt_valdps || '',
            multa: formData?.cnt_multa || '',
            juros: formData?.cnt_juros || '',
            valpag: formData?.cnt_valpag || '',
            descon: formData?.cnt_descon || '',
            despes: formData?.cnt_despes || '',
            caxger: formData?.cnt_caxger || '',
            numcax: formData?.cnt_numcax || '',
            numdoc: formData?.cnt_numdoc || '',
            docpag: formData?.cnt_docpag || '',
            damov: formData?.cnt_damov || '',
            datven: formData?.cnt_datven || '',
            datpag: formData?.cnt_datpag || '',
            datatz: formData?.cnt_datatz || '',
            datincl: formData?.cnt_datincl || '',
            usuario: formData?.cnt_usuario || '',
            codcont: formData?.cnt_codcont || ''
          };
          response = await ApiPut(
            `/contas`,
            body,
            localStorage.getItem('sessionToken')
          );

          if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
            alert(response?.data?.mensagem?.msg);
            return;
          }
          alert('Conta atualizada com sucesso');
        }
      };

      if (actions[insertOrUpdate]) {
        await actions[insertOrUpdate]();
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSearch();
      handleReset();
    }
  };

  const handleLiquida = async () => {
    let valdps = 0;
    let juros = 0;
    let multa = 0;
    let descon = 0;
    let valpag = 0;

    if (typeof formDataLiquida.cnt_valdps === 'string') {
      valdps = parseFloat(
        String(formDataLiquida.cnt_valdps)?.replace(/\./g, '').replace(',', '.')
      );
    } else {
      valdps = parseFloat(formDataLiquida.cnt_valdps);
    }

    if (typeof formDataLiquida.cnt_juros === 'string') {
      juros = parseFloat(
        String(formDataLiquida.cnt_juros)?.replace(/\./g, '').replace(',', '.')
      );
    } else {
      juros = parseFloat(formDataLiquida.cnt_juros);
    }

    if (typeof formDataLiquida.cnt_multa === 'string') {
      multa = parseFloat(
        String(formDataLiquida.cnt_multa)?.replace(/\./g, '').replace(',', '.')
      );
    } else {
      multa = parseFloat(formDataLiquida.cnt_multa);
    }

    if (typeof formDataLiquida.cnt_descon === 'string') {
      descon = parseFloat(
        String(formDataLiquida.cnt_descon)?.replace(/\./g, '').replace(',', '.')
      );
    } else {
      descon = parseFloat(formDataLiquida.cnt_descon);
    }

    if (typeof formDataLiquida.cnt_valpag === 'string') {
      valpag = parseFloat(
        String(formDataLiquida.cnt_valpag)?.replace(/\./g, '').replace(',', '.')
      );
    } else {
      valpag = parseFloat(formDataLiquida.cnt_valpag);
    }

    const body = {
      id: formDataLiquida.cnt_numcon || '',
      tipo: formDataLiquida.cnt_tippag || '',
      valor: valdps || '',
      juros: juros || '',
      multa: multa || '',
      desconto: descon || '',
      valorPago: valpag || '',
      codigoMoeda: formDataLiquida.cnt_codmoe || '',
      valorMoeda: formDataLiquida.cnt_valmoe || '',
      cambio: formDataLiquida.cnt_cambio || '',
      tipoDocumento: formDataLiquida.cnt_tipdoc || '',
      codigoCartao: formDataLiquida.cnt_codcar || '',
      numeroDocumentoPagamento: formDataLiquida.cnt_docpag || '',
      codigoBanco: formDataLiquida.cnt_codban || '',
      dataPagamento: formDataLiquida.cnt_datpag || '',
      dataBaixa: formDataLiquida.cnt_datbai || '',
      observacao: formDataLiquida.cnt_observ || '',
      usuario: localStorage.getItem('sessionUsuario'),
      usuarioLiquida: localStorage.getItem('sessionUsuario'),
      caixaLiquida: formDataLiquida.cnt_numcax
    };

    try {
      const response = await NewApiPost(
        '/contas/liquidar',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      alert('Conta Liquidada');
      handleSearch();
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setTitle('');
    setFormData({});
    setFormDataLiquida({});
    setActiveTab(1);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const resEmpresa = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const resLoja = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const resUndAdm = response3.data.tabela;

      const response4 = await NewApiGet(
        '/tabela/centroCusto',
        localStorage.getItem('sessionToken')
      );
      const resCencus = response4.data.tabela;

      const response5 = await NewApiGet(
        '/tabela/naturezaDespesa',
        localStorage.getItem('sessionToken')
      );
      const resNatdes = response5.data.tabela;

      const response6 = await NewApiGet(
        '/tabela/fonteRecurso',
        localStorage.getItem('sessionToken')
      );
      const resFontrec = response6.data.tabela;

      const response7 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const resForPag = response7.data.tabela;

      const response8 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const resTipPag = response8.data.tabela;

      const response9 = await NewApiGet(
        '/tabela/projeto',
        localStorage.getItem('sessionToken')
      );
      const resProjeto = response9.data.tabela;

      const response10 = await NewApiGet(
        '/tabela/banco',
        localStorage.getItem('sessionToken')
      );
      const resBanco = response10.data.tabela;

      const response11 = await NewApiGet(
        '/tabela/planoConta',
        localStorage.getItem('sessionToken')
      );
      const resPlanoConta = response11.data.tabela;

      const response12 = await NewApiGet(
        '/v1/caixa',
        localStorage.getItem('sessionToken')
      );
      setDataCaixa(response12.data?.dados);

      const response13 = await NewApiGet(
        '/tabela/tipoDocumento',
        localStorage.getItem('sessionToken')
      );
      const resTipoDocumento = response13.data.tabela;

      const response14 = await NewApiGet(
        '/tabela/cartaoCredito',
        localStorage.getItem('sessionToken')
      );
      const resTipoCartao = response14.data.tabela;

      const response15 = await NewApiGet(
        '/tabela/dolar',
        localStorage.getItem('sessionToken')
      );
      const resDolar = response15.data.tabela;

      const response16 = await NewApiGet(
        '/tabela/tipoMoeda',
        localStorage.getItem('sessionToken')
      );
      const resMoeda = response16.data.tabela;

      const response17 = await ApiGet(
        '/tabela/contabil',
        {},
        localStorage.getItem('sessionToken')
      );
      const resLancamento = response17.data.dados;

      setOptions([
        resEmpresa, // 0
        resLoja, // 1
        resUndAdm, // 2
        resCencus, // 3
        resNatdes, // 4
        resFontrec, // 5
        resForPag, // 6
        resTipPag, // 7
        resProjeto, // 8
        resBanco, // 9
        resPlanoConta, // 10
        resTipoDocumento, // 11
        resTipoCartao, //12
        resDolar, //13
        resMoeda, // 14
        resLancamento //15
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Contas') {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabNavOrRel = activeTab === 1 || activeTab === 2;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Contas"
      tabIndex="-1"
      aria-labelledby="lblContas"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblContas"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Contas ${title && `- ${title}`}`}
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-Contas' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(1);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Relatorio
                </p>
                <p style={{ display: 'none' }}>Cadastro</p>
                <p style={{ display: 'none' }}>Caixa Liquidar</p>
                <p style={{ display: 'none' }}>Confirmar Liquidar</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setTitle={setTitle}
                    setFormDataLiquida={setFormDataLiquida}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data?.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                    insertOrUpdate={insertOrUpdate}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <GridCaixa
                    dataCaixa={dataCaixa}
                    setDataCaixa={setDataCaixa}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    dataEmpresa={props.dataEmpresa}
                    formDataLiquida={formDataLiquida}
                    setFormDataLiquida={setFormDataLiquida}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
                  id="tab5"
                >
                  <Liquidar
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                    formDataLiquida={formDataLiquida}
                    setFormDataLiquida={setFormDataLiquida}
                    activeTab={activeTab}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTransferencia"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    // alert('Em atualização');
                    setTitle('Adicionar');
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(3);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {(activeTab === 3 || activeTab === 4 || activeTab === 5) && (
              // <button
              //   type="button"
              //   className="btn btn-danger"
              //   onClick={handleReset}
              //   style={{ fontSize: props.tamTexto }}
              // >
              //   <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
              // </button>
              <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />
            )}

            {((activeTab === 3 && viewOrUpdate !== 'view') ||
              activeTab === 5) && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (activeTab === 3) {
                    handleSave();
                  } else {
                    handleLiquida();
                  }
                }}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-check"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
