import { formatRequestArray } from '../../../../functions/formatRequestArray';
import { ApiGet, NewApiGet } from '../../../../services/apiService';

export const fetchDataTabelaTipoDataValor = async (
  centroCusto = '',
  fonteRecurso = '',
  natureza = '',
  startDate = '',
  endDate = '',
  tipoPeriodo = '',
  realizado = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/contasGerais`,
      {
        centroCusto: formatRequestArray(centroCusto),
        fonteRecurso: formatRequestArray(fonteRecurso),
        natureza: formatRequestArray(natureza),
        tipoPeriodo: tipoPeriodo,
        dataInicial: startDate,
        dataFinal: endDate,
        realizado: realizado
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataGraficoBarraSum = async (
  centroCusto = '',
  fonteRecurso = '',
  natureza = '',
  startDate = '',
  endDate = '',
  tipoPeriodo = '',
  realizado = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/centroCusto`,
      {
        centroCusto: formatRequestArray(centroCusto),
        fonteRecurso: formatRequestArray(fonteRecurso),
        natureza: formatRequestArray(natureza),
        tipoPeriodo: tipoPeriodo,
        dataInicial: startDate,
        dataFinal: endDate,
        realizado: realizado
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataLinhaPorPeriodo = async (
  centroCusto = '',
  fonteRecurso = '',
  natureza = '',
  startDate = '',
  endDate = '',
  tipoPeriodo = '',
  realizado = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/valorConta`,
      {
        centroCusto: formatRequestArray(centroCusto),
        fonteRecurso: formatRequestArray(fonteRecurso),
        natureza: formatRequestArray(natureza),
        tipoPeriodo: tipoPeriodo,
        dataInicial: startDate,
        dataFinal: endDate,
        realizado: realizado
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataGraficoPizza = async (
  centroCusto = '',
  fonteRecurso = '',
  natureza = '',
  startDate = '',
  endDate = '',
  tipoConta = '',
  tipoPeriodo = '',
  agrupamento = '',
  realizado = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/valorPorCentroCusto`,
      {
        centroCusto: formatRequestArray(centroCusto),
        fonteRecurso: formatRequestArray(fonteRecurso),
        natureza: formatRequestArray(natureza),
        tipoPeriodo: tipoPeriodo,
        tipoConta: tipoConta,
        agrupamento: agrupamento,
        dataInicial: startDate,
        dataFinal: endDate,
        realizado: realizado
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataTabela2Grupos = async (
  centroCusto = '',
  fonteRecurso = '',
  natureza = '',
  startDate = '',
  endDate = '',
  tipoConta = '',
  tipoPeriodo = '',
  grupo = '',
  subgrupo = ''
) => {
  try {
    const response = await ApiGet(
      `/v1/graficos/tabelasReceitaDespesaNaturezaCC`,
      {
        dataInicial: startDate,
        dataFinal: endDate,
        tipoPeriodo: tipoPeriodo,
        tipoConta: tipoConta,
        centroCusto: formatRequestArray(centroCusto),
        fonteRecurso: formatRequestArray(fonteRecurso),
        natureza: formatRequestArray(natureza),
        grupo: grupo,
        subgrupo: subgrupo
      },
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataDropdown = async () => {
  try {
    const response = await NewApiGet(
      `/v1/graficos/filtro/CentroCustoNaturezaFonteReceita`,
      localStorage.getItem('sessionToken')
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
