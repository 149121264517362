import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import {
  ApiGet,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CadastroItems } from './GridItems/Cadastro';
import { GridItems } from './GridItems/GridItems';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

import { format } from 'date-fns';

export const Consumo = (props) => {
  function getDateRange() {
    const today = new Date();
    const currentMonth = today.getMonth();

    const startDate = new Date(today);
    startDate.setMonth(currentMonth - 3);

    const start = startDate;
    const end = today;

    return { start, end };
  }

  const { start, end } = getDateRange();

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [formDataItems, setFormDataItems] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    datmovInicial: format(start, 'dd/MM/yyyy'),
    datmovFinal: format(end, 'dd/MM/yyyy')
  });
  const [dataItems, setDataItems] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [insertOrUpdateItems, setInsertOrUpdateItems] = useState('');
  const [viewOrUpdateItems, setViewOrUpdateItems] = useState('');
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState('');

  const handleSearch = async () => {
    const tamNumcon = dataFiltro.numcon
      ? dataFiltro.numcon.padStart(10, '0')
      : '';

    let datmov = null;

    const conditionDatmovInicial =
      dataFiltro.datmovInicial !== '' &&
      dataFiltro.datmovInicial !== null &&
      dataFiltro.datmovInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datmovFinal !== '' &&
      dataFiltro.datmovFinal !== null &&
      dataFiltro.datmovFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datmov = [dataFiltro.datmovInicial, dataFiltro.datmovFinal];
    }

    const body = {
      empresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      unidadeAdministrativa: dataFiltro.undadm || '',
      unidadeSolicitante: dataFiltro.undsol || '',
      tipoConsumo: dataFiltro.tipcon || '',
      numeroConsumo: tamNumcon || '',
      dataMovimento: datmov || ''
    };

    try {
      const response = await ApiGet(
        '/v1/consumo',
        body,
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.dados);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const body = {
      dataMovimento: formData.dataMovimento,
      dataFechamento: formData.dataFechamento,
      numeroConsumo: formData.numeroConsumo,
      numeroCarga: formData.numeroCarga,
      codigoPessoa: formData.codigoPessoa,
      codigoVendedor: formData.codigoVendedor,
      numeroSaida: formData.numeroSaida,
      valor: formData.valor,
      tipoConsumo: formData.tipoConsumo,

      unidadeSolicitante: formData.unidadeSolicitante || '',
      numeroMesa: formData.numeroMesa || '',
      controleUnidadeSolicitante: formData.controleUnidadeSolicitante || '',
      numeroDocumento: formData.numeroDocumento || '',
      prazo: formData.prazo || '',
      formaPagamento: formData.formaPagamento || '',
      tipoPagamento: formData.tipoPagamento || '',
      dataAtualizacao: formData.dataAtualizacao || '',
      usuario: formData.usuario || '',
      tabelaPreco: formData.tabelaPreco || '',
      modalidade: formData.modalidade || '',
      valorDesconto: formData.valorDesconto || '',
      comissao: formData.comissao || '',
      horaAtualizacao: formData.horaAtualizacao || '',
      tipoMoeda: formData.tipoMoeda || '',
      valorMoeda: formData.valorMoeda || '',
      cambio: formData.cambio || '',
      totalItensPagamento: formData.totalItensPagamento || '',
      totalInformadoPagamento: formData.totalInformadoPagamento || '',
      numeroFatura: formData.numeroFatura || '',
      codigoGuia: formData.codigoGuia || '',
      codigoExternoCS: formData.codigoExternoCS || '',
      numeroTerminal: formData.numeroTerminal || '',
      ipEquipamento: formData.ipEquipamento || '',
      localConsumo: formData.localConsumo || '',
      dataEntrega: formData.dataEntrega || '',
      horaEntrega: formData.horaEntrega || '',
      entrega: formData.entrega || '',
      enderecoEntrega: formData.enderecoEntrega || '',
      ufEntrega: formData.ufEntrega || '',
      cidadeEntrega: formData.cidadeEntrega || '',
      bairroEntrega: formData.bairroEntrega || '',
      prefixoEntrega: formData.prefixoEntrega || '',
      cepEntrega: formData.cepEntrega || ''
    };

    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await NewApiPost(
          '/v1/consumo',
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/v1/consumo/${formData.ConsumoID}`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Consumo registrada com sucesso');
      } else {
        alert('Consumo atualizada com sucesso');
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSearch();
      handleReset();
    }
  };

  const handleSaveItems = async () => {
    const body = {
      numeroConsumo: formDataItems.numeroConsumo || '',
      tipoConsumo: formDataItems.tipoConsumo || '',
      sequenciaItem: formDataItems.sequenciaItem || '',
      item: formDataItems.item || '',
      descricaoItem: formDataItems.descricaoItem || '',
      unidade: formDataItems.unidade || '',
      quantidadePedida: formDataItems.quantidadePedida || '',
      quantidadeRecebida: formDataItems.quantidadeRecebida || '',
      valor: formDataItems.valor || '',
      valorItem: formDataItems.valorItem || '',
      custoItem: formDataItems.custoItem || '',
      usuario: formDataItems.usuario || '',
      comissaoItem: formDataItems.comissaoItem || '',
      quantidadeSubItem: formDataItems.quantidadeSubItem || '',
      numeroComissao: formDataItems.numeroComissao || '',
      situacaoItem: formDataItems.situacaoItem || '',
      dataAtualizacao: formDataItems.dataAtualizacao || '',
      horaAtualizacao: formDataItems.horaAtualizacao || '',
      observacao: formDataItems.observacao || '',
      situacaoPagamento: formDataItems.situacaoPagamento || '',
      detqtdN: formDataItems.detqtdN || '',
      detqtdS: formDataItems.detqtdS || '',
      valorDescontoItem: formDataItems.valorDescontoItem || '',
      situacaoFechamento: formDataItems.situacaoFechamento || '',
      dataInclusao: formDataItems.dataInclusao || '',
      horaInclusao: formDataItems.horaInclusao || '',
      codigoPessoa: formDataItems.codigoPessoa || '',
      unidadeProducao: formDataItems.unidadeProducao || '',
      unidadeSolicitacao: formDataItems.unidadeSolicitacao || '',
      codigoExternoICS: formDataItems.codigoExternoICS || '',
      observacaoItem: formDataItems.observacaoItem || '',
      cowSeq: formDataItems.cowSeq || '',
      icwSeq: formDataItems.icwSeq || '',
      quantidadeAtendida: formDataItems.quantidadeAtendida || '',
      usuarioAtendimento: formDataItems.usuarioAtendimento || '',
      dataAtendimento: formDataItems.dataAtendimento || '',
      horaAtendimento: formDataItems.horaAtendimento || '',
      numeroConsumoOriginal: formDataItems.numeroConsumoOriginal || '',
      vendedorProduto: formDataItems.vendedorProduto || '',
      quantidadeProduzida: formDataItems.quantidadeProduzida || '',
      numeroMesa: formDataItems.numeroMesa || '',

      empresa: formDataItems.empresa || '',
      loja: formDataItems.loja || ''
    };

    let response = null;

    try {
      if (insertOrUpdateItems === 'insert') {
        response = await NewApiPost(
          '/v1/consumo/item',
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/v1/consumo/item/${formDataItems.itemID}`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdateItems === 'insert') {
        alert('Consumo registrada com sucesso');
      } else {
        alert('Consumo atualizada com sucesso');
      }

      const response1 = await ApiGet(
        '/v1/consumo/item',
        { numeroConsumo: formData.numeroConsumo },
        localStorage.getItem('sessionToken')
      );
      setDataItems(response1.data.dados);
    } catch (error) {
      console.log(error);
    } finally {
      handleResetItems();
    }
  };

  const handleReset = () => {
    setTitle('');
    setFormData({});
    setActiveTab(1);
  };

  const handleResetItems = () => {
    setFormDataItems({});
    setActiveTab(3);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const resEmpresa = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const resLoja = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const resUndAdm = response3.data.tabela;

      const response4 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const resTipoPag = response4.data.apartamento;

      const response5 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const resFormaPag = response5.data.tabela;

      setOptions([resEmpresa, resLoja, resUndAdm, resTipoPag, resFormaPag]);
    } catch (err) {
      console.log(err);
    }
  };
  const handleButtonClick = () => {
    if (activeTab === 2 || activeTab === 3) {
      handleReset();
    } else if (activeTab === 4) {
      handleResetItems();
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Consumo') {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabCadOrItems = activeTab === 2 || activeTab === 3;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Consumo"
      tabIndex="-1"
      aria-labelledby="lblCompras"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblCompras"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Consumo ${title && `- ${title}`}`}
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-Consumo' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Cadastro
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 3 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(3);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Items
                </p>
                <p style={{ display: 'none' }}>Cadastro Items</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setDataItems={setDataItems}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <GridItems
                    dataItems={dataItems}
                    formData={formData}
                    setDataItems={setDataItems}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setFormDataItems={setFormDataItems}
                    setActiveTab={setActiveTab}
                    setViewOrUpdateItems={setViewOrUpdateItems}
                    setInsertOrUpdateItems={setInsertOrUpdateItems}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <CadastroItems
                    formDataItems={formDataItems}
                    setFormDataItems={setFormDataItems}
                    viewOrUpdateItems={viewOrUpdateItems}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {/* Filtro */}
            {activeTab === 1 && (
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTransferencia"
                aria-controls="offcanvasExample"
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            )}

            {/* Voltar */}
            {(activeTab === 2 || activeTab === 3 || activeTab === 4) && (
              // <button
              //   type="button"
              //   className="btn btn-danger"
              //   onClick={() => {
              //     if (activeTab === 2 || activeTab === 3) {
              //       handleReset();
              //     } else if (activeTab === 4) {
              //       handleResetItems();
              //     }
              //   }}
              //   style={{ fontSize: props.tamTexto }}
              // >
              //   <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
              // </button>
              <ReturnButton
                onClick={handleButtonClick}
                fontSize={props.tamTexto}
              />
            )}

            {/* Adicionar */}
            {(activeTab === 1 || activeTab === 3) && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (activeTab === 1) {
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(2);
                  } else if (activeTab === 3) {
                    setInsertOrUpdateItems('insert');
                    setViewOrUpdateItems('update');
                    setActiveTab(4);
                  }
                }}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            )}

            {/* Salvar Consumo */}
            {activeTab === 2 && viewOrUpdate !== 'view' && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-check"></i>
              </button>
            )}

            {/* Salvar Items */}
            {activeTab === 4 && viewOrUpdateItems !== 'view' && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveItems}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-check"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
