import React from 'react';

export const ReturnButton = ({ onClick, fontSize }) => (
  <button
    type="button"
    className="btn btn-primary"
    onClick={onClick}
    style={{ fontSize }}
  >
    <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
  </button>
);
