import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import { ApiGet, NewApiGet } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Relatorio } from './relatorio/Relatorio';

export function EntradaRecep(props) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [dataFiltro, setDataFiltro] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [options, setOptions] = useState([]);

  const handleSearch = async () => {
    const tamNumres = dataFiltro.numres?.padStart(10, '0') || '';
    const tamNument = dataFiltro.nument?.padStart(10, '0') || '';
    const tamNumapa = dataFiltro.numapa?.padStart(5, ' ') || '';
    const tamGrupo = dataFiltro.grupo?.padStart(10, '0') || '';
    const nome = dataFiltro.nomhos?.toUpperCase();

    let datent = null;

    const conditionDatentInicial =
      dataFiltro.datentInicial !== '' &&
      dataFiltro.datentInicial !== null &&
      dataFiltro.datentInicial !== undefined;
    const conditionDatentFinal =
      dataFiltro.datentFinal !== '' &&
      dataFiltro.datentFinal !== null &&
      dataFiltro.datentFinal !== undefined;

    if (conditionDatentInicial && conditionDatentFinal) {
      datent = [dataFiltro.datentInicial, dataFiltro.datentFinal];
    }

    const body = {
      empresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      undadm: dataFiltro.undadm || '',
      numres: tamNumres || '',
      nument: tamNument || '',
      numapa: tamNumapa || '',
      nomhos: nome || '',
      datent: datent || '',
      datsai: [''],
      datatz: [''],
      val1dia: '',
      email: '',
      precel: '',
      telcel: '',
      grupo: tamGrupo || '',
      tipapa: dataFiltro.tipapt || '',
      codevn: dataFiltro.evento || '',
      codhos: dataFiltro.tippes || ''
    };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      { condition: tamNumres, campo: 'Num Res', conteudo: tamNumres },
      { condition: tamNument, campo: 'Num Ent', conteudo: tamNument },
      {
        condition: dataFiltro.sitres,
        campo: 'Sit Res',
        conteudo: dataFiltro.sitres
      },
      {
        condition: dataFiltro.numapa,
        campo: 'Num Apa',
        conteudo: dataFiltro.numapa
      },
      {
        condition: nome,
        campo: 'Nome',
        conteudo: nome
      },
      {
        condition: dataFiltro.grupo,
        campo: 'Grupo',
        conteudo: dataFiltro.grupo
      },
      { condition: datent, campo: 'Dt Ent', conteudo: datent }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/v1/entrada',
        { entrada: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      const sortedData = response.data.entrada.sort(
        (a, b) => a.ent_numapa - b.ent_numapa
      );

      setData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };

  const handleSave = async () => {
    alert('Em atualização');
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const res1 = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const res2 = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const res3 = response3.data.tabela;

      const dateAtual = new Date();
      const diaAtual = dateAtual.getDate().toString().padStart(2, '0');
      const mesAtual = String(dateAtual.getMonth() + 1).padStart(2, '0');
      const anoAtual = dateAtual.getFullYear().toString();
      const formatDataAtual = `${anoAtual}/${mesAtual}/${diaAtual}`;

      const date = new Date(dateAtual);
      date.setFullYear(parseInt(anoAtual) + 1);
      const dia = String(date.getDate()).padStart(2, '0');
      const mes = String(date.getMonth() + 1).padStart(2, '0');
      const ano = date.getFullYear();
      const formatData = `${ano}/${mes}/${dia}`;

      const response4 = await ApiGet(
        '/v1/evento/',
        {
          evento: {
            codevn: '',
            evento: '',
            datini: [],
            datfim: [formatDataAtual, formatData]
          }
        },
        localStorage.getItem('sessionToken')
      );
      const res4 = response4.data.evento;

      const response5 = await ApiGet(
        '/apartamento/tipo',
        {},
        localStorage.getItem('sessionToken')
      );
      const res5 = response5.data.apartamento;

      const response6 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const res6 = response6.data.tabela;

      const response7 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const res7 = response7.data.tabela;

      const response8 = await NewApiGet(
        'tabela/cartaoCredito/unique',
        localStorage.getItem('sessionToken')
      );
      const res8 = response8.data.tabela;

      const response9 = await NewApiGet(
        '/tabela/bandeiraCartao',
        localStorage.getItem('sessionToken')
      );
      const res9 = response9.data.tabela;

      const response10 = await NewApiGet(
        '/tabela/banco',
        localStorage.getItem('sessionToken')
      );
      const res10 = response10.data.tabela;

      const response11 = await NewApiGet(
        '/tabela/centroCusto',
        localStorage.getItem('sessionToken')
      );
      const res11 = response11.data.tabela;

      const response12 = await NewApiGet(
        '/tabela/dolar',
        localStorage.getItem('sessionToken')
      );
      const res12 = response12.data.tabela;

      const response13 = await NewApiGet(
        '/tabela/naturezaDespesa',
        localStorage.getItem('sessionToken')
      );
      const res13 = response13.data.tabela;

      const response14 = await NewApiGet(
        '/tabela/fonteRecurso',
        localStorage.getItem('sessionToken')
      );
      const res14 = response14.data.tabela;

      const response15 = await NewApiGet(
        '/tabela/projeto',
        localStorage.getItem('sessionToken')
      );
      const res15 = response15.data.tabela;

      const response16 = await NewApiGet(
        '/tabela/contrato',
        localStorage.getItem('sessionToken')
      );
      const res16 = response16.data.tabela;

      const response17 = await NewApiGet(
        '/tabela/tipoHospede',
        localStorage.getItem('sessionToken')
      );
      const res17 = response17.data.tabela;

      const response18 = await NewApiGet(
        '/tabela/tipoApartamento',
        localStorage.getItem('sessionToken')
      );
      const res18 = response18.data.tabela;

      const response19 = await NewApiGet(
        '/tabela/tipoDiariaConsumo',
        localStorage.getItem('sessionToken')
      );
      const res19 = response19.data.tabela;

      const response20 = await NewApiGet(
        '/apartamento/listar',
        localStorage.getItem('sessionToken')
      );
      const res20 = response20.data.objeto;

      const response21 = await NewApiGet(
        '/tabela/convenio',
        localStorage.getItem('sessionToken')
      );
      const res21 = response21.data.tabela;

      const response22 = await NewApiGet(
        '/tabela/meioComunicacao',
        localStorage.getItem('sessionToken')
      );
      const res22 = response22.data.tabela;

      const response23 = await NewApiGet(
        '/tabela/tipoAssunto',
        localStorage.getItem('sessionToken')
      );
      const res23 = response23.data.tabela;

      const response24 = await NewApiGet(
        '/tabela/pais',
        localStorage.getItem('sessionToken')
      );
      const res24 = response24.data.tabela;

      const response25 = await NewApiGet(
        '/tabela/estado',
        localStorage.getItem('sessionToken')
      );
      const res25 = response25.data.tabela;

      const response26 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_cidade',
          select: ['*'],
          order: {
            column: 'tci_nomcid',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const res26 = response26.data.dados.rows;

      setOptions([
        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
        res9,
        res10,
        res11,
        res12,
        res13,
        res14,
        res15,
        res16,
        res17,
        res18,
        res19,
        res20,
        res21,
        res22,
        res23,
        res24,
        res25,
        res26
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-EntradaCheckin') {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabNavOrRel = activeTab === 1 || activeTab === 2;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-EntradaCheckin"
      tabIndex="-1"
      aria-labelledby="lblEntrada"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblEntrada"
              style={{ fontSize: props.tamTitulo }}
            >
              Entrada/Check-in
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-EntradaCheckin' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(1);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Relatorio
                </p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data?.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>

                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    options={options}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    // setInsertOrUpdate('insert');
                    // setViewOrUpdate('update');
                    // setActiveTab(3);
                    alert('Em atualização');
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 3 || activeTab === 4 ? (
              <>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button> */}
                <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleSave()}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
