import { useState } from 'react';

import { CadDadosComplementares } from './CadDadosComplementares';
import { CadInfoBancario } from './CadInfoBancario';
import { CadObservacao } from './CadObservacao';
import { CadTelefones } from './CadTelefones';

export function CadFuncionario(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Código</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Personalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codpes"
              value={props.formData?.pes_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_nome"
              value={props.formData?.pes_nome || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">CNPJ</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">CGF</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Nome</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_sexo"
              value={props.formData?.pes_sexo || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Nacionalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_rg"
              value={props.formData?.pes_rg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">País</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Estado</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_certnasc"
              value={props.formData?.pes_certnasc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cep</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Cidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_certnasc"
              value={props.formData?.pes_certnasc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Endereço</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Compl.</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Bairro</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Contato</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              value={props.formData?.pes_npassprt || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">D. Cad</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="pes_npassprt"
              // value={props.formData?.pes_npassprt || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Telefones
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ fontSize: props.tamTexto }}
          >
            Inf. Bancárias
          </p>
          <p
            className={`nav-link ${
              activeTab === 3 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(3)}
            style={{ fontSize: props.tamTexto }}
          >
            Observações
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadTelefones
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tabCadastro2"
          >
            <CadInfoBancario
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
            id="tabCadastro3"
          >
            <CadObservacao
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
        </div>
      </div>

      <div className="col-12 mt-3">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab2 === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab2(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Endereço de Cobrança
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab2 === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadDadosComplementares
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
