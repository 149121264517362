export const CadGeral = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Empresa</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_codemp"
              value={props.formData?.cnt_codemp || ''}
              onChange={(e) => {
                const filter = props.options[0]?.filter(
                  (option) => option.emp_codemp === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_codemp: e.target.value,
                  emp_fantasia: filter[0]?.emp_fantasia
                });
              }}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            >
              <option defaultValue></option>
              {props.options[0]?.map((option, index) => (
                <option key={index} value={option.emp_codemp}>
                  {option.emp_codemp === props.formData?.cnt_codemp
                    ? option.emp_codemp
                    : option.emp_fantasia}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="emp_fantasia"
              value={props.formData?.emp_fantasia || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Loja</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_loja"
              value={props.formData?.cnt_loja || ''}
              onChange={(e) => {
                const filter = props.options[1]?.filter(
                  (option) => option.loj_codloj === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_loja: e.target.value,
                  loj_denloj: filter[0]?.loj_denloj
                });
              }}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.loj_codloj}>
                  {option.loj_codloj === props.formData?.cnt_loja
                    ? option.loj_codloj
                    : option.loj_denloj}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="loj_denloj"
              value={props.formData?.loj_denloj || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód Und.</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Und. Administrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_undadm"
              value={props.formData?.cnt_undadm || ''}
              onChange={(e) => {
                const filter = props.options[2]?.filter(
                  (option) => option.codigo_unidade_adm === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  cnt_undadm: e.target.value,
                  uad_nomuad: filter[0]?.nome_unidade_adm
                });
              }}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            >
              <option defaultValue></option>
              {props.options[2]?.map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm === props.formData?.cnt_undadm
                    ? option.codigo_unidade_adm
                    : option.nome_unidade_adm}
                </option>
              ))}
            </select>
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="uad_nomuad"
              value={props.formData?.uad_nomuad || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Num Conta</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Tp Conta</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Tip Ger</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Doc Ref</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_numcon"
              value={props.formData?.cnt_numcon || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_tipcon"
              value={props.formData?.cnt_tipcon || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_tipger"
              value={props.formData?.cnt_tipger || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_numser"
              value={props.formData?.cnt_numser || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cod Cliente</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Cliente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_codpes"
              value={props.formData?.cnt_codpes || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_nompes"
              value={props.formData?.cnt_nompes || ''}
              onChange={props.handleInput}
              disabled={props.insertOrUpdate === 'insert' ? false : true}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Descrição Despesa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_dscdps"
              value={props.formData?.cnt_dscdps || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observações</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="cnt_observ"
              value={props.formData?.cnt_observ || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Escrit</span>
          </div>
        </div>
        <div className="col-4 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="cnt_escrit"
              value={props.formData?.cnt_escrit || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="S">S - Escriturado</option>
              <option value="N">N - Não Escriturado</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
