import { NumericFormat } from 'react-number-format';

export const CadPedidos = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">N. Pedido</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Vendedor</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_empresa"
              value={props.formData?.ped_empresa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label">Fornecedor</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Ope.</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Observação</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_empresa"
              value={props.formData?.ped_empresa || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-9">
            <textarea
              className="form-control form-control-sm border border-dark"
              name="pes_observ"
              value={props.formData?.pes_observ || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              rows="4"
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_observ"
              value={props.formData?.ped_observ || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">F. de Pagto</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">T. Pagto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_codope"
              value={props.formData?.ped_codope || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_codope"
              value={props.formData?.ped_codope || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Desconto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_prazo"
              value={props.formData?.ped_prazo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Dat. Mov.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ped_prazo"
              // value={props.formData?.ped_prazo || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
