export function CadInfoBancario(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codpes"
              value={props.formData?.pes_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Agência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Conta Corrente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_sexo"
              value={props.formData?.pes_sexo || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codpes"
              value={props.formData?.pes_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Agência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_email"
              value={props.formData?.pes_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Conta Poupança</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_sexo"
              value={props.formData?.pes_sexo || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
