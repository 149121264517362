export const CadHistorico = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Fornc. menor Vl.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_menfor"
              value={props.formData?.itm_menfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_menfor"
              value={props.formData?.itm_menfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Fornc. Ant.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_antfor"
              value={props.formData?.itm_antfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_antfor"
              value={props.formData?.itm_antfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Último Fornec.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_ultfor"
              value={props.formData?.itm_ultfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_ultfor"
              value={props.formData?.itm_ultfor || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Men. Vl. Compra</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. menor</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_menval"
              value={props.formData?.itm_menval || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_dmenval"
              value={props.formData?.itm_dmenval?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Vl Compra Ant.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. Comrpa Ant.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_antval"
              value={props.formData?.itm_antval || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_dantval"
              value={props.formData?.itm_dantval?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Últ. Vl Compra</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. Últ. Compra</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_ultval"
              value={props.formData?.itm_ultval || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_dultval"
              value={props.formData?.itm_dultval?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
