import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import { ApiGet, ApiPut, NewApiPost } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

export const Item = (props) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [options, setOptions] = useState([]);

  const handleSearch = async () => {
    const tamItem = dataFiltro.item ? dataFiltro.item.padStart(10, '0') : '';

    const body = {
      item: tamItem || '',
      sititm: dataFiltro.sititm || '',
      tipo: dataFiltro.tipo || '',
      grupo: dataFiltro.grupo || '',
      subgrupo: dataFiltro.subgrupo || '',
      descri: dataFiltro.descri || ''
    };

    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'item',
          select: [
            'itm_item',
            'itm_sititm',
            'itm_tipo',
            'itm_grupo',
            'itm_subgrupo',
            'itm_descri'
          ],
          where: {
            itm_item: `${body.item}`,
            itm_sititm: `${body.sititm}`,
            itm_tipo: `${body.tipo}`,
            itm_grupo: `${body.grupo}`,
            itm_subgrupo: `${body.subgrupo}`,
            itm_descri: { like: `%${body.descri}%` }
          },
          order: {
            column: 'itm_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.dados.rows);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const body = {
      id: formData?.itm_item || '',
      empresa: localStorage.getItem('sessionEmpresa'),
      loja: localStorage.getItem('sessionLoja'),
      sititm: formData?.itm_sititm || '',
      tipo: formData?.itm_tipo || '',
      grupo: formData?.itm_grupo || '',
      subgrupo: formData?.itm_subgrupo || '',
      descricao: formData?.itm_descri || '',
      destecres: formData?.itm_destecres || '',
      destec: formData?.itm_destec || '',
      descriabv: formData?.itm_descriabv || '',
      unidade: formData?.itm_unidade || '',
      marca: formData?.item_marca || '',
      embala: formData?.itm_embala || '',
      qtembal: formData?.itm_qtembal || '',
      modelo: formData?.itm_modelo || '',
      codmrg: formData?.itm_codmrg || '',
      sitmrg: formData?.itm_sitmrg || '',
      valor: formData?.itm_valor || '',
      valormin: formData?.itm_valormin || '',
      valprom: formData?.itm_valprom || '',
      validprom: formData?.itm_validprom || '',
      custopr: formData?.itm_custopr || '',
      custfin: formData?.itm_custfin || '',
      icms: formData?.itm_icms || '',
      icmsant: formData?.itm_icmsant || '',
      ipi: formData?.itm_ipi || '',
      frete: formData?.itm_frete || '',
      taxser: formData?.itm_taxser || '',
      clasfisc: formData?.itm_clasfisc || '',
      clafimp: formData?.itm_clafimp || '',
      tipcal: formData?.itm_tipcal || '',
      lucro: formData?.itm_lucro || '',
      codfab: formData?.itm_codfab || '',
      menfor: formData?.itm_menfor || '',
      menval: formData?.itm_menval || '',
      dmenval: formData?.itm_dmenval || '',
      antval: formData?.itm_antval || '',
      antfor: formData?.itm_antfor || '',
      dantval: formData?.itm_dantval || '',
      ultfor: formData?.itm_ultfor || '',
      ultval: formData?.itm_ultval || '',
      dultval: formData?.itm_dultval || '',
      ultqtd: formData?.itm_ultqtd || '',
      nfult: formData?.itm_nfult || '',
      valornf: formData?.itm_valornf || '',
      rua: formData?.itm_rua || '',
      pzmedpg: formData?.itm_pzmedpg || '',
      coluna: formData?.itm_coluna || '',
      prateleira: formData?.itm_prateleira || '',
      dremarc: formData?.itm_dremarc || '',
      datmov: formData?.itm_datmov || '',
      usuario: formData?.itm_usuario || '',
      foratab: formData?.itm_foratab || '',
      subitm: formData?.itm_subitm || '',
      comitm: formData?.itm_comitm || '',
      varejo: formData?.itm_varejo || '',
      qtdatc: formData?.itm_qtdatc || '',
      foto: formData?.itm_foto || '',
      ctrcons: formData?.itm_ctrcons || '',
      comext: formData?.itm_comext || '',
      prealter: formData?.itm_prealter || '',
      dataltpre: formData?.itm_dataltpre || '',
      tipopr: formData?.itm_tipopr || '',
      ncm: formData?.itm_ncm || '',
      gtin: formData?.itm_gtin || '',
      cest: formData?.itm_cest || '',
      codtins: formData?.itm_codtins || '',
      qtdmaxins: formData?.itm_qtdmaxins || '',
      orinfe: formData?.itm_orinfe || '',
      nivcompx: formData?.itm_nivcompx || '',
      cstipi: formData?.itm_cstip || ''
    };

    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await NewApiPost(
          `/v1/item`,
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/v1/item`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Item registrado com sucesso');
      } else {
        alert('Item atualizado com sucesso');
      }
    } catch (error) {
      console.log(error);
    }

    handleReset();
  };

  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };

  const handleCombobox = async () => {
    try {
      const response1 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_clafiscal',
          select: ['*'],
          order: {
            column: 'fis_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resClassFiscal = response1.data.dados.rows;

      const response2 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_tipo_insumo',
          select: ['*'],
          order: {
            column: 'tin_nomtins',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resTipoInsumo = response2.data.dados.rows;

      setOptions([
        resClassFiscal, //0
        resTipoInsumo //1
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCombobox();
  }, []);

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Item') {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Item"
      tabIndex="-1"
      aria-labelledby="lblCompras"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblCompras"
              style={{ fontSize: props.tamTitulo }}
            >
              Item
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-Item' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    options={options}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTransferencia"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 2 && (
              <>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button> */}
                <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
