export function CadDadosComplementares(props) {
  return (
    <div>
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Sexo</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">RG</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. Nasc.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_sexo"
              value={props.formData?.pes_sexo || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_sexo: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="M">MASCULINO</option>
              <option value="F">FEMININO</option>
            </select>
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_rg"
              value={props.formData?.pes_rg || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="pes_datnas"
              value={props.formData?.pes_datnas?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Profissão</span>
          </div>
          {/* <div className="col-6">
            <span className="col-form-label">Local de Trab.</span>
          </div> */}
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_profis"
              value={props.formData?.pes_profis || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          {/* <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_loctra"
              value={props.formData?.pes_loctra || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div> */}
        </div>

        {/* <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label">End. Trab.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Cep</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_endtra"
              value={props.formData?.pes_endtra || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_ceptra"
              value={props.formData?.pes_ceptra || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div> */}
      </div>

      <div className="col-12">
        <div className="col-12 row">
          {/* <div className="col-4">
            <span className="col-form-label">Est. Civil</span>
          </div> */}
        </div>
        <div className="col-12 row">
          {/* <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_estciv"
              value={props.formData?.pes_estciv || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div> */}
        </div>

        {/* <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Dt. Admis</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Renda</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="pes_datadim"
              value={props.formData?.pes_datadim?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_renda"
              value={props.formData?.pes_renda || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Bairro</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cid</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_baitra"
              value={props.formData?.pes_baitra || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_cidtra"
              value={props.formData?.pes_cidtra || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
