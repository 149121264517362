import { useState } from 'react';

import { CadComplemento } from './CadComplemento';
import { CadDadosFiscais } from './CadDadosFiscais';
import { CadHistorico } from './CadHistorico';
import { CadItem } from './CadItem';

export const Cadastro = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            activeTab === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(1)}
          style={{ fontSize: props.tamTexto }}
        >
          Dados do Item
        </p>
        <p
          className={`nav-link ${
            activeTab === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(2)}
          style={{ fontSize: props.tamTexto }}
        >
          Complemento
        </p>
        <p
          className={`nav-link ${
            activeTab === 3 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(3)}
          style={{ fontSize: props.tamTexto }}
        >
          Histórico
        </p>
        <p
          className={`nav-link ${
            activeTab === 4 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => setActiveTab(4)}
          style={{ fontSize: props.tamTexto }}
        >
          Dados Fiscais
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadItem
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            options={props.options}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
          id="tabCadastro2"
        >
          <CadComplemento
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            options={props.options}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
          id="tabCadastro3"
        >
          <CadHistorico
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            options={props.options}
          />
        </div>
        <div
          className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
          id="tabCadastro4"
        >
          <CadDadosFiscais
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            options={props.options}
          />
        </div>
      </div>
    </>
  );
};
