import { ApiDelete, ApiGet } from '../../../services/apiService';
import { Grid } from './Grid';

import Swal from 'sweetalert2';

export function CtrNavegacao(props) {
  const handleEdit = async (codpes) => {
    const body = { codpes };

    try {
      const response = await ApiGet(
        '/pessoa',
        { pessoa: body },
        localStorage.getItem('sessionToken')
      );
      const res = response.data.pessoa[0];

      const id_pais = props.options[1]?.filter(
        (option) => option?.tps_despais === res?.pes_paisres
      );

      const id_estado = props.options[2]?.filter(
        (option) => option?.tuf_id === res?.pes_iduf
      );

      const id_cidade = props.options[3]?.filter(
        (option) => option?.tci_codcid === res?.pes_cidresibge
      );

      props.setFormData({
        ...res,
        id_pais: id_pais[0]?.tps_id_pais || '',
        pes_iduf: id_estado[0]?.tuf_id || '',
        pes_cidresibge: id_cidade[0]?.tci_codcid || ''
      });
      props.setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id, nome) => {
    try {
      Swal.fire({
        title: 'Deletar',
        html: `Deseja deletar esse registro: ${nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiDelete(`/pessoa/${id}`, {});
          if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
            alert(response?.data?.mensagem?.msg);
            return;
          }

          alert('Registro deletado com sucesso');
          props.handleSearch();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
    />
  );
}
