export let menu_condicao = [
  {
    hospedagem: {
      coluna: '1',
      linha: '1',
      nome: 'Hospedagem/Vendas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Hospedagem'
    }
  },
  {
    reserva: {
      coluna: '1',
      linha: '2',
      nome: 'Reservas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Reservas'
    }
  },
  {
    confirmacaoReserva: {
      coluna: '1',
      linha: '3',
      nome: 'Confirmação da Reserva',
      situacao: 'INATIVO',
      status: 'DEV',
      chave: 'Menu-ConfirmacaoReserva'
    }
  },
  {
    checkin: {
      coluna: '1',
      linha: '4',
      nome: 'Check-in Avulso',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-CheckinOnline'
    }
  },
  {
    eventos: {
      coluna: '1',
      linha: '5',
      nome: 'Eventos',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Eventos'
    }
  },
  {
    entrada: {
      coluna: '1',
      linha: '6',
      nome: 'Entrada/Check-in',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-EntradaCheckin'
    }
  },
  {
    saida: {
      coluna: '1',
      linha: '7',
      nome: 'Saída/Check-out',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-SaidaCheckout'
    }
  },
  {
    consumo: {
      coluna: '1',
      linha: '8',
      nome: 'Consumo',
      situacao: 'INATIVO',
      status: 'DEV',
      chave: 'Menu-Consumo'
    }
  },
  {
    pedidoVenda: {
      coluna: '1',
      linha: '9',
      nome: 'Pedidos de Venda',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-PedidoVenda'
    }
  },

  {
    pedidoCompra: {
      coluna: '2',
      linha: '1',
      nome: 'Pedidos de Compra',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-PedidoCompra'
    }
  },
  {
    compras: {
      coluna: '2',
      linha: '2',
      nome: 'Compras',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Compras'
    }
  },
  {
    estoque: {
      coluna: '2',
      linha: '3',
      nome: 'Estoque',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Estoque'
    }
  },
  {
    transferencia: {
      coluna: '2',
      linha: '4',
      nome: 'Transferências',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Transferencias'
    }
  },
  {
    parceiros: {
      coluna: '2',
      linha: '5',
      nome: 'Parceiros',
      situacao: 'INATIVO',
      status: 'DEV',
      chave: 'Menu-Parceiros'
    }
  },
  {
    portaria: {
      coluna: '2',
      linha: '6',
      nome: 'Controle de Veículos/Portaria',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-ControlePortaria'
    }
  },
  {
    item: {
      coluna: '2',
      linha: '7',
      nome: 'Item',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Item'
    }
  },

  {
    contas: {
      coluna: '3',
      linha: '1',
      nome: 'Contas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Contas'
    }
  },
  {
    contasPagas: {
      coluna: '3',
      linha: '2',
      nome: 'Contas Pagas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-ContasPag'
    }
  },
  {
    financeiro: {
      coluna: '3',
      linha: '3',
      nome: 'Financeiro',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Financeiro'
    }
  },
  {
    contabil: {
      coluna: '3',
      linha: '4',
      nome: 'Contábil',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Contabil'
    }
  },
  {
    pessoa: {
      coluna: '3',
      linha: '5',
      nome: 'Pessoa',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Pessoa'
    }
  },
  {
    manutencao: {
      coluna: '3',
      linha: '6',
      nome: 'Manutenção',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Manutencao'
    }
  }
];

menu_condicao.sort((a, b) => {
  const keyA = Object.keys(a)[0];
  const keyB = Object.keys(b)[0];
  return a[keyA].linha - b[keyB].linha;
});
