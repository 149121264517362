export function CadInfoBancario(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_codban"
              value={props.formData?.pes_codban || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_codban: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[4]?.map((option, index) => (
                <option key={index} value={option.ban_codban}>
                  {option.ban_desban}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Agência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codage"
              value={props.formData?.pes_codage || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Conta Corrente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_conta"
              value={props.formData?.pes_conta || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_codbanp"
              value={props.formData?.pes_codbanp || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_codbanp: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[4]?.map((option, index) => (
                <option key={index} value={option.ban_codban}>
                  {option.ban_desban}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Agência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codagep"
              value={props.formData?.pes_codagep || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-7">
            <span className="col-form-label">Conta Poupança</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_contap"
              value={props.formData?.pes_contap || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
