import InputMask from 'react-input-mask';

export function CadCadastroClientes(props) {
  return (
    <div>
      <div className="col-12">
        {props.insertOrUpdate === 'insert' && (
          <>
            <div className="col-12 row">
              <div className="col-6">
                <span className="col-form-label">Tipo de Pessoa</span>
              </div>
            </div>
            <div className="col-12 row">
              <div className="col-6">
                <select
                  className="form-select form-select-sm border border-dark"
                  name="codtpes"
                  value={props.formData?.codtpes || ''}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      codtpes: e.target.value
                    });
                  }}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                >
                  <option defaultValue></option>
                  {props.options[0]?.map((option, index) => (
                    <option key={index} value={option.tip_codtpes}>
                      {option.tip_nomtpes}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Código</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Personalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codpes"
              value={props.formData?.pes_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_fisjur"
              value={props.formData?.pes_fisjur || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_fisjur: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              <option value="FÍSICA">FÍSICA</option>
              <option value="JURÍDICA">JURÍDICA</option>
            </select>
          </div>
        </div>

        <div className="col-12 row">
          {props.formData?.pes_fisjur === 'JURÍDICA' ? (
            <>
              <div className="col-4">
                <span className="col-form-label">CNPJ</span>
              </div>
              <div className="col-4">
                <span className="col-form-label">INS. ESTADUAL</span>
              </div>
              <div className="col-4">
                <span className="col-form-label">INSC. MUNICIPAL</span>
              </div>
            </>
          ) : (
            <div className="col-4">
              <span className="col-form-label">CPF</span>
            </div>
          )}
        </div>
        <div className="col-12 row">
          {props.formData?.pes_fisjur === 'JURÍDICA' ? (
            <>
              <div className="col-4">
                <InputMask
                  mask="99.999.999/9999-99"
                  maskChar=""
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="pes_cgc"
                  value={props.formData?.pes_cgc || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control form-control-sm border border-dark"
                  name="pes_cgf"
                  value={props.formData?.pes_cgf || ''}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      props.handleInput(e);
                    }
                  }}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control form-control-sm border border-dark"
                  name="pes_inscmunic"
                  value={props.formData?.pes_inscmunic || ''}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      props.handleInput(e);
                    }
                  }}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
            </>
          ) : (
            <div className="col-4">
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                type="text"
                className="form-control form-control-sm border border-dark"
                name="pes_cpf"
                value={props.formData?.pes_cpf || ''}
                onChange={props.handleInput}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          )}
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Nome</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_nome"
              value={props.formData?.pes_nome || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Nacionalidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_nacion"
              value={props.formData?.pes_nacion || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_nacion: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.tps_despais}>
                  {option.tps_despais}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">País</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Estado</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_paisres"
              value={props.formData?.pes_paisres || ''}
              onChange={(e) => {
                const filter = props.options[1]?.filter(
                  (option) => option.tps_despais === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  pes_paisres: e.target.value,
                  id_pais: filter[0]?.tps_id_pais,
                  pes_uf: '',
                  id_estado: '',
                  pes_cidade: '',
                  id_cidade: '',
                  pes_cidresibge: '',
                  pes_iduf: ''
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.tps_despais}>
                  {option.tps_despais}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_uf"
              value={props.formData?.pes_uf || ''}
              onChange={(e) => {
                const filter = props.options[2]?.filter(
                  (option) => option.tuf_coduf === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  pes_uf: e.target.value,
                  id_estado: filter[0]?.tuf_id,
                  pes_iduf: filter[0]?.tuf_id,
                  pes_cidade: '',
                  id_cidade: '',
                  pes_cidresibge: ''
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[2]
                ?.filter((item) => item.tuf_id_pais === props.formData?.id_pais)
                .map((option, index) => (
                  <option key={index} value={option.tuf_coduf}>
                    {option.tuf_descuf}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cep</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Cidade</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <InputMask
              mask="99.999-999"
              maskChar=""
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_cep"
              value={props.formData?.pes_cep || ''}
              onChange={props.handleInput}
              onBlur={(e) => props.handleCep(e)}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-8">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_cidade"
              value={props.formData?.pes_cidade || ''}
              onChange={(e) => {
                const filter = props.options[3]?.filter(
                  (option) => option.tci_nomcid === e.target.value
                );

                props.setFormData({
                  ...props.formData,
                  pes_cidade: e.target.value,
                  id_cidade: filter[0]?.tci_codcid,
                  pes_cidresibge: filter[0]?.tci_codcid
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[3]
                ?.filter((item) => item.tci_coduf === props.formData?.pes_iduf)
                .map((option, index) => (
                  <option key={index} value={option.tci_nomcid}>
                    {option.tci_nomcid}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Endereço</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_endere"
              value={props.formData?.pes_endere || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Compl.</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Bairro</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_comple"
              value={props.formData?.pes_comple || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_bairro"
              value={props.formData?.pes_bairro || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Atividade</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Id. Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">D. Cad</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_tipativ"
              value={props.formData?.pes_tipativ || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_tipativ: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[5]?.map((option, index) => (
                <option key={index} value={option.atv_codativ}>
                  {option.atv_desativ}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_idebanc"
              value={props.formData?.pes_idebanc || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="pes_datcad"
              value={props.formData?.pes_datcad?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">T. Venda</span>
          </div>
          <div className="col-8">
            <span className="col-form-label">Cont. Ori</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_tipcom"
              value={props.formData?.pes_tipcom || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-8">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_contato"
              value={props.formData?.pes_contato || ''}
              onChange={props.handleInput}
              style={{ textTransform: 'uppercase' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Host Grupo</span>
          </div>
          {props.formData?.pes_fisjur === 'JURÍDICA' && (
            <div className="col-9">
              <span className="col-form-label">NOME FANTASIA</span>
            </div>
          )}
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="number"
              className="form-control form-control-sm border border-dark"
              name="pes_codemp"
              value={props.formData?.pes_codemp || ''}
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  props.handleInput(e);
                }
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          {props.formData?.pes_fisjur === 'JURÍDICA' && (
            <div className="col-9">
              <input
                type="text"
                className="form-control form-control-sm border border-dark"
                name="pes_nomefan"
                value={props.formData?.pes_nomefan || ''}
                onChange={(e) => props.handleInput(e)}
                style={{ textTransform: 'uppercase' }}
                disabled={props.viewOrUpdate === 'view' ? true : false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
