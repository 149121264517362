import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { ApiGet } from '../../../../services/apiService';
import { ChartBar, ChartLine, ChartPieQt } from '../../graficos';
import { TabelaDinamica } from '../../graficos/TabelaDinamica';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';

export const AnaliseVendaConsumo = () => {
  const [dataLine, setDataLine] = useState([]);
  const [dataPieVL, setDataPieVL] = useState([]);
  const [dataPieQT, setDataPieQT] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataSheet, setDataSheet] = useState([]);
  const [itemConsumo, setItemConsumo] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);

  const loadDataFilter = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(loadDataFilter.startDate);
  const [endDate, setEndDate] = useState(loadDataFilter.endDate);

  useEffect(() => {
    let itens = itemConsumo
      .map((selected) => {
        let matchedItem = dataDropdown.find(
          (item) => item.item === selected.value
        );
        return matchedItem ? matchedItem.value : null;
      })
      .filter((id) => id !== null);
    const requests = [
      //Grafico de pizza Valor Total
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'consumo',
          where: {
            con_datmov: [startDate, endDate],
            ico_item: { in: itens }
          },
          select: [
            'ROUND(SUM(con_valor)::numeric, 2) AS sum',
            'ico_desitm AS nome',
            'ico_item AS id'
          ],
          groupBy: 'id, nome',
          join: [
            'left',
            'item_consumo',
            'consumo.con_numcon = item_consumo.ico_numcon'
          ],
          order: { column: 'sum', order: 'DESC' }
        },
        localStorage.getItem('sessionToken')
      ),
      //Grafico de pizza Quantidade
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'consumo',
          where: { con_datmov: [startDate, endDate], ico_item: { in: itens } },
          select: [
            'SUM(ico_quantped) AS sum',
            'ico_desitm AS nome',
            'ico_item AS id'
          ],
          groupBy: 'id, nome',
          join: [
            'left',
            'item_consumo',
            'consumo.con_numcon = item_consumo.ico_numcon'
          ],
          order: { column: 'sum', order: 'DESC' }
        },
        localStorage.getItem('sessionToken')
      ),
      //Grafico de linha
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'consumo',
          where: {
            con_datmov: [startDate, endDate],
            ico_item: { in: itens }
          },
          select: [
            'ROUND(SUM(con_valor)::numeric, 2) AS sum',
            "DATE_TRUNC('month', con_datmov) AS month"
          ],
          groupBy: 'month',
          join: [
            'left',
            'item_consumo',
            'consumo.con_numcon = item_consumo.ico_numcon'
          ],
          order: { column: 'month', order: 'ASC' }
        },
        localStorage.getItem('sessionToken')
      ),
      //Grafico de barra
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'consumo',
          where: { con_datmov: [startDate, endDate], ico_item: { in: itens } },
          select: [
            'ico_desitm AS nome',
            'ROUND(SUM(con_valor)::numeric, 2) AS sum'
          ],
          groupBy: 'nome',
          join: [
            'left',
            'item_consumo',
            'consumo.con_numcon = item_consumo.ico_numcon'
          ],
          order: { column: 'sum', order: 'DESC' }
        },
        localStorage.getItem('sessionToken')
      ),
      //Tabela
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'consumo',
          where: { con_datmov: [startDate, endDate], ico_item: { in: itens } },
          select: [
            'ico_desitm AS nome',
            'ico_item AS id',
            'ROUND(SUM(con_valor)::numeric, 2) AS total_valdia',
            'ROUND(AVG(con_valor)::numeric, 2) AS avg_val1dia',
            'ico_unidade AS unidade',
            'SUM(ico_quantped) AS total_items'
          ],
          join: [
            'left',
            'item_consumo',
            'consumo.con_numcon = item_consumo.ico_numcon'
          ],
          groupBy: 'nome, id, unidade',
          order: { column: 'nome', order: 'ASC' }
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        let gDataPieVL = responses[0].data.dados.rows.map((item) => ({
          nome: item.nome,
          sum: item.sum
        }));
        let gDataPieQT = responses[1].data.dados.rows.map((item) => ({
          nome: item.nome,
          sum: parseInt(item.sum)
        }));
        let dataLine = responses[2].data.dados.rows.map((item) => ({
          periodo: item.month,
          sum: item.sum
        }));
        let dataBar = responses[3].data.dados.rows
          .map((item) => ({
            cliente: item.nome,
            sumvalitem: item.sum
          }))
          .sort((a, b) => b.sumvalitem - a.sumvalitem)
          .slice(0, 10);
        setDataPieVL(gDataPieVL);
        setDataPieQT(gDataPieQT);
        setDataLine(dataLine);
        setDataBar(dataBar);
        setDataSheet(responses[4].data.dados.rows);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [startDate, endDate, itemConsumo, dataDropdown]);

  useEffect(() => {
    const requests = [
      //filtro dropdown
      ApiGet(
        '/v1/selectTabela',
        {
          from: 'item_consumo',
          select: ['ico_desitm AS nome', 'ico_item AS id'],
          groupBy: 'id, nome',
          orderBy: { column: 'ico_item', order: 'ASC' }
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        let listaItems = responses[0].data.dados.rows
          .map((item) => {
            return { item: item.nome, value: item.id, codigo: item.id };
          })
          .filter((item) => item.item !== undefined && item.item !== '');

        setDataDropdown(listaItems);
      })
      .catch((error) => {
        console.error('Error ao recuperar data para dropdown', error);
      });
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Vendas Consumo </h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="ITENS"
          listaItems={dataDropdown.sort((a, b) => a.item.localeCompare(b.item))}
          value={itemConsumo}
          setValue={setItemConsumo}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartLine data={dataLine} />
          <TabelaDinamica
            title="Tabela Vendas Consumo"
            data={dataSheet}
            headers={[
              {
                label: 'CodigoItem',
                value: 'id',
                align: 'right'
              },
              {
                label: 'Descrição',
                value: 'nome',
                align: 'left'
              },
              {
                label: 'Receita de Consumo',
                value: 'total_valdia',
                align: 'right',
                type: 'monetary'
              },
              {
                label: 'Valor Médio',
                value: 'avg_val1dia',
                align: 'right',
                type: 'monetary'
              },
              {
                label: 'Unidade',
                value: 'unidade',
                align: 'right',
                type: 'integer'
              },
              {
                label: 'Total de Itens',
                value: 'total_items',
                align: 'right',
                type: 'integer'
              }
            ]}
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center text-center w-100 h-100">
          <ChartPieQt
            data={dataPieVL}
            titulo={'Gráfico Valor Total'}
            isMonetary={true}
          />
          <ChartPieQt
            data={dataPieQT}
            titulo={'Gráfico Quantidade'}
            isMonetary={false}
          />
          <ChartBar data={dataBar} title={'Top 10 Itens'} />
        </div>
      </div>
    </div>
  );
};
