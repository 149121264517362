import { useEffect, useState } from 'react';

import { ApiGet } from '../../../../services/apiService';

export function GridVendedor(props) {
  const [nome, setNome] = useState('');
  const [dataVendedor, setdataVendedor] = useState([]);

  const handleVendedor = async () => {
    try {
      const responseTipo = await ApiGet(
        '/v1/selectTabela',
        {
          select: ['tip_codtpes', 'tip_nomtpes'],
          from: 'tab_tipo_pes',
          where: {
            tip_nomtpes: 'VENDEDOR'
          }
        },
        localStorage.getItem('sessionToken')
      );
      const tipoPessoa = responseTipo.data.dados.rows[0].tip_codtpes;

      const response = await ApiGet(
        '/v1/selectTabela',
        {
          select: ['pes_codpes', 'pes_nome'],
          from: 'pessoa',
          where: {
            tpt_codtpes: tipoPessoa
          },
          join: ['left', 'pessoa_tipo', 'pes_codpes = tpt_codpes'],
          order: { column: 'pes_nome', order: 'ASC' }
        },
        localStorage.getItem('sessionToken')
      );
      setdataVendedor(response.data.dados.rows);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleVendedor();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <input
          className="form-control form-control-sm border border-dark"
          type="text"
          placeholder="Digite o nome do vendedor"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>

      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            <th scope="col" className="grid">
              Cód. Vendedor
            </th>
            <th scope="col" className="grid">
              Nome Vendedor
            </th>
            <th scope="col" className="grid"></th>
          </tr>
        </thead>
        <tbody>
          {dataVendedor
            ?.filter((item) => item.pes_nome.includes(nome.toUpperCase()))
            .map((item, index) => (
              <tr key={index}>
                <td scope="row">{item.pes_codpes}</td>
                <td>{item.pes_nome}</td>
                <td>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        props.setFormData({
                          ...props.formData,
                          pes_codven: item.pes_codpes
                        });
                        props.setActiveTabCadastro(2);
                      }}
                    >
                      <i className="fa-solid fa-check"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
