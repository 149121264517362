const mod11 = (Num) => {
  let nSoma = 0;
  let nTamanho = Num.length;
  try {
    for (let nContador = 2; nContador <= nTamanho + 1; nContador++) {
      nSoma =
        nSoma + nContador * parseInt(Num.charAt(nTamanho - nContador + 1));
    }
  } catch (e) {
    nSoma = 0;
  }

  // Calcula o resto após a divisão por 11
  let nR_Resto = nSoma - Math.floor(nSoma / 11) * 11;

  // Se o resto for menor que 2, o dígito é zero
  if (nR_Resto < 2) {
    return '0';
  } else {
    return String(11 - nR_Resto);
  }
};

export const isValidCpf = (cpf) => {
  if (cpf.length !== 11) {
    return false;
  } else {
    let Prov = cpf.substring(0, 9);
    let Digito = cpf.substring(9, 11);

    Prov = Prov + mod11(Prov);
    Prov = Prov + mod11(Prov);

    if (Digito === Prov.substring(9, 11)) {
      return true;
    } else {
      return false;
    }
  }
};
