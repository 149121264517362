import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import { isValidCpf } from '../../functions/isValidCpf';
import {
  ApiGet,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

export function Pessoa(props) {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabCadastro, setActiveTabCadastro] = useState(2);
  const [dataFiltro, setDataFiltro] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');

  const [options, setOptions] = useState([]);

  const handleSearch = async () => {
    let datnas = null;

    const conditionDatnasInicial =
      dataFiltro.datnasInicial !== '' &&
      dataFiltro.datnasInicial !== null &&
      dataFiltro.datnasInicial !== undefined;
    const conditionDatnasFinal =
      dataFiltro.datnasFinal !== '' &&
      dataFiltro.datnasFinal !== null &&
      dataFiltro.datnasFinal !== undefined;

    if (conditionDatnasInicial && conditionDatnasFinal) {
      datnas = [dataFiltro.datnasInicial, dataFiltro.datnasFinal];
    }

    const body = {
      id: dataFiltro.tipoPessoa || '',
      codigoPessoa: dataFiltro.codpes || '',
      cpf: dataFiltro.cpf || '',
      rg: '',
      nome: dataFiltro.nome || '',
      dataNascimento: datnas,
      cnpj: dataFiltro.cgc || ''
    };

    try {
      const response = await ApiGet(
        '/pessoa/tipo',
        body,
        localStorage.getItem('sessionToken')
      );
      setData(response.data.pessoa);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    if (formData?.pes_cpf !== '') {
      const isValid = isValidCpf(formData?.pes_cpf?.replace(/\D/g, ''));

      if (!isValid) {
        alert('CPF inválido');
        return;
      }
    }

    const body = {
      pes_codpes: formData?.pes_codpes || '',
      pes_fisjur: formData?.pes_fisjur || '',
      pes_tipo: formData?.pes_tipo || '',
      pes_nome: formData?.pes_nome?.toUpperCase() || '',
      pes_sexo: formData?.pes_sexo || '',
      pes_endere: formData?.pes_endere || '',
      pes_endant: formData?.pes_endant || '',
      pes_bairro: formData?.pes_bairro || '',
      pes_nombai: formData?.pes_nombai || '',
      pes_comple: formData?.pes_comple || '',
      pes_cidade: formData?.pes_cidade || '',
      pes_uf: formData?.pes_uf || '',
      pes_cep: formData?.pes_cep?.replace(/\D/g, '') || '',
      pes_loctra: formData?.pes_loctra || '',
      pes_endtra: formData?.pes_endtra || '',
      pes_baitra: formData?.pes_baitra || '',
      pes_nbaitra: formData?.pes_nbaitra || '',
      pes_cidtra: formData?.pes_cidtra || '',
      pes_uftra: formData?.pes_uftra || '',
      pes_ceptra: formData?.pes_ceptra?.replace(/\D/g, '') || '',
      pes_preres: formData?.pes_preres || '',
      pes_telres: formData?.pes_telres || '',
      pes_prere2: formData?.pes_prere2 || '',
      pes_telre2: formData?.pes_telre2 || '',
      pes_precel: formData?.pes_precel || '',
      pes_telcel: formData?.pes_telcel || '',
      pes_precom: formData?.pes_precom || '',
      pes_telcom: formData?.pes_telcom || '',
      pes_ramal: formData?.pes_ramal || '',
      pes_pager: formData?.pes_pager || '',
      pes_email: formData?.pes_email?.toLowerCase() || '',
      pes_hpage: formData?.pes_hpage || '',
      pes_rg: formData?.pes_rg || '',
      pes_cpf: formData?.pes_cpf?.replace(/\D/g, '') || '',
      pes_cgc: formData?.pes_cgc?.replace(/\D/g, '') || '',
      pes_cgf: formData?.pes_cgf || '',
      pes_nacion: formData?.pes_nacion || '',
      pes_estciv: formData?.pes_estciv || '',
      pes_codemp: formData?.pes_codemp || '',
      pes_matben: formData?.pes_matben || '',
      pes_profis: formData?.pes_profis?.toUpperCase() || '',
      pes_codcateg: formData?.pes_codcateg || '',
      pes_tipativ: formData?.pes_tipativ || '',
      pes_comissao: formData?.pes_comissao || '',
      pes_renda: formData?.pes_renda || '',
      pes_datadim: formData?.pes_datadim || '',
      pes_datnas: formData?.pes_datnas || '',
      pes_datcad: formData?.pes_datcad || '',
      pes_conjug: formData?.pes_conjug || '',
      pes_cpfcon: formData?.pes_cpfcon || '',
      pes_naccon: formData?.pes_naccon || '',
      pes_idecon: formData?.pes_idecon || '',
      pes_procon: formData?.pes_procon || '',
      pes_numdep: formData?.pes_numdep || '',
      pes_contato: formData?.pes_contato?.toUpperCase() || '',
      pes_nomprp: formData?.pes_nomprp || '',
      pes_cpfprp: formData?.pes_cpfprp || '',
      pes_limcre: formData?.pes_limcre || '',
      pes_sldlim: formData?.pes_sldlim || '',
      pes_sitcre: formData?.pes_sitcre || '',
      pes_valultcom: formData?.pes_valultcom || '',
      pes_observ: formData?.pes_observ || '',
      pes_datalt: formData?.pes_datalt || '',
      pes_horalt: formData?.pes_horalt || '',
      pes_usuario: formData?.pes_usuario || '',
      pes_endentr: formData?.pes_endere || '', //formData?.pes_endentr || '',
      pes_baientr: formData?.pes_bairro || '', //formData?.pes_baientr || '',
      pes_cidentr: formData?.pes_cidade || '', //formData?.pes_cidentr || '',
      pes_ufentr: formData?.pes_uf || '', //formData?.pes_ufentr || '',
      pes_cepentr: formData?.pes_cep?.replace(/\D/g, '') || '', //formData?.pes_cepentr || '',
      pes_prefentr: formData?.pes_prefentr || '',
      pes_endcob: formData?.pes_endere || '', //formData?.pes_endcob || '',
      pes_baicob: formData?.pes_bairro || '', //formData?.pes_baicob || '',
      pes_cidcob: formData?.pes_cidade || '', //formData?.pes_cidcob || '',
      pes_ufcob: formData?.pes_uf || '', //formData?.pes_ufcob || '',
      pes_cepcob: formData?.pes_cep?.replace(/\D/g, '') || '', //formData?.pes_cepcob || '',
      pes_prefcob: formData?.pes_prefcob || '',
      pes_codrota: formData?.pes_codrota || '',
      pes_codzona: formData?.pes_codzona || '',
      pes_zonaesp: formData?.pes_zonaesp || '',
      pes_dultcomp: formData?.pes_dultcomp || '',
      pes_msaldodev: formData?.pes_msaldodev || '',
      pes_dmsalddev: formData?.pes_dmsalddev || '',
      pes_qmatrazo: formData?.pes_qmatrazo || '',
      pes_dvultpres: formData?.pes_dvultpres || '',
      pes_qprotesto: formData?.pes_qprotesto || '',
      pes_dultprot: formData?.pes_dultprot || '',
      pes_vtcmpmes: formData?.pes_vtcmpmes || '',
      pes_svencido: formData?.pes_svencido || '',
      pes_svencer: formData?.pes_svencer || '',
      pes_codcre: formData?.pes_codcre || '',
      pes_codven: formData?.pes_codven || '',
      pes_sdevedor: formData?.pes_sdevedor || '',
      pes_codcor: formData?.pes_codcor || '',
      pes_tipcom: formData?.pes_tipcom || '',
      pes_forpag: formData?.pes_forpag || '',
      pes_tippag: formData?.pes_tippag || '',
      pes_idebanc: formData?.pes_idebanc || '',
      pes_grpcred: formData?.pes_grpcred || '',
      pes_nomefan: formData?.pes_nomefan || '',
      pes_codori: formData?.pes_codori || '',
      pes_codban: formData?.pes_codban || '',
      pes_codage: formData?.pes_codage || '',
      pes_conta: formData?.pes_conta || '',
      pes_codbanp: formData?.pes_codbanp || '',
      pes_codagep: formData?.pes_codagep || '',
      pes_contap: formData?.pes_contap || '',
      pes_sitpes: formData?.pes_sitpes || '',
      pes_tipdoc: formData?.pes_tipdoc || '',
      pes_npassprt: formData?.pes_npassprt || '',
      pes_certnasc: formData?.pes_certnasc || '',
      pes_cie: formData?.pes_cie || '',
      pes_orgexp: formData?.pes_orgexp || '',
      pes_ddicel: formData?.pes_ddicel || '',
      pes_ddires: formData?.pes_ddires || '',
      pes_paisres: formData?.pes_paisres || '',
      pes_envfnrh: formData?.pes_envfnrh || '',
      pes_cidresibge: formData?.pes_cidresibge || '',
      pes_pesseq: formData?.pes_pesseq || '',
      pes_pednfe: formData?.pes_pednfe || '',
      pes_inscmunic: formData?.pes_inscmunic || '',
      pes_iduf: formData?.pes_iduf || '',
      pes_chat_id: formData?.pes_chat_id || ''
    };

    const bodyPessoaTipo = {
      tpt_codpes: formData.pes_codpes,
      tpt_codtpes: formData.codtpes
    };

    let codpes = null;
    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await NewApiPost(
          '/pessoa',
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/pessoa/${formData.pes_codpes}`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Cadastro realizado com sucesso');
        codpes = response.data.pessoa;
      } else {
        alert('Atualização realizada com sucesso');
      }
    } catch (error) {
      console.log(error);
    }

    // if (
    //   Array.isArray(bodyPessoaTipo.tpt_codtpes) &&
    //   bodyPessoaTipo.tpt_codtpes?.length > 0
    // ) {
    //   await Promise.all(
    //     bodyPessoaTipo.tpt_codtpes.map(async (option) => {
    //       try {
    //         const response = await NewApiPost(
    //           '/pessoa/tipo',
    //           {
    //             codigoPessoa: formData.pes_codpes || codpes,
    //             codigoTipo: option
    //           },
    //           localStorage.getItem('sessionToken')
    //         );
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     })
    //   );
    // }

    if (insertOrUpdate === 'insert') {
      try {
        const response = await NewApiPost(
          '/pessoa/tipo',
          {
            codigoPessoa: formData.pes_codpes || codpes,
            codigoTipo: bodyPessoaTipo.tpt_codtpes
          },
          localStorage.getItem('sessionToken')
        );
      } catch (error) {
        console.log(error);
      }
    }

    handleReset();
  };

  const handleReset = () => {
    if (activeTabCadastro === 9) {
      setActiveTabCadastro(2);
    } else {
      setFormData({});
      setActiveTab(1);
    }
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/tipoPessoa',
        localStorage.getItem('sessionToken')
      );
      const resTippes = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/pais',
        localStorage.getItem('sessionToken')
      );
      const resPais = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/estado',
        localStorage.getItem('sessionToken')
      );
      const resEstado = response3.data.tabela;

      const response4 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_cidade',
          select: ['*'],
          order: {
            column: 'tci_nomcid',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resCidade = response4.data.dados.rows;

      const response5 = await NewApiGet(
        '/tabela/banco',
        localStorage.getItem('sessionToken')
      );
      const resBanco = response5.data.tabela;

      const response6 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_tipo_ativ',
          select: ['*'],
          order: {
            column: 'atv_desativ',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resAtividade = response6.data.dados.rows;

      const response7 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_sitcli',
          select: ['*'],
          order: {
            column: 'tsc_dessit',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resSitCred = response7.data.dados.rows;

      const response8 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_credito',
          select: ['*'],
          order: {
            column: 'cre_codcre',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resLimCred = response8.data.dados.rows;

      const response9 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_grupocredito',
          select: ['*'],
          order: {
            column: 'tgc_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resGruCred = response9.data.dados.rows;

      const response10 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_rota',
          select: ['*'],
          order: {
            column: 'rot_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      const resRota = response10.data.dados.rows;

      setOptions([
        resTippes, //0
        resPais, //1,
        resEstado, //2
        resCidade, //3
        resBanco, //4
        resAtividade, //5
        resSitCred, //6
        resLimCred, //7
        resGruCred, //8
        resRota //9
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCombobox();
  }, []);

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-Pessoa') {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Pessoa"
      tabIndex="-1"
      aria-labelledby="lblPessoa"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblPessoa"
              style={{ fontSize: props.tamTitulo }}
            >
              Pessoa
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          {props.isOpen === 'Menu-Pessoa' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasReserva"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    options={options}
                  />
                </div>

                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  <Cadastro
                    activeTab={activeTab}
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    options={options}
                    insertOrUpdate={insertOrUpdate}
                    activeTabCadastro={activeTabCadastro}
                    setActiveTabCadastro={setActiveTabCadastro}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasReserva"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    const codPais = options[1]?.filter(
                      (option) => option.tps_despais === 'BRASIL'
                    );

                    setFormData({
                      pes_nacion: 'BRASIL',
                      pes_paisres: 'BRASIL',
                      id_pais: codPais[0]?.tps_id_pais,
                      // pes_datcad: new Date().toISOString().split('T')[0],
                      pes_pednfe: 'N',
                      // pes_datalt: new Date().toISOString().split('T')[0],
                      // pes_horalt: new Date().toLocaleTimeString([], {
                      //   hour: '2-digit',
                      //   minute: '2-digit',
                      //   second: '2-digit'
                      // }),
                      pes_usuario: localStorage
                        .getItem('sessionUsuario')
                        ?.toUpperCase(),
                      pes_sitpes: 'INA'
                    });

                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 2 && (
              <>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button> */}
                <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />

                {viewOrUpdate !== 'view' && activeTabCadastro !== 9 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
