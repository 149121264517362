import { useEffect, useState } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import {
  ApiGet,
  ApiPost,
  ApiPut,
  NewApiGet,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { GridItems } from './GridItems/GridItems';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { Relatorio } from './relatorio/Relatorio';

export const PedidoVenda = (props) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({
    sitped: 'N',
    tipped: 'V'
  });
  const [dataItems, setDataItems] = useState([]);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [options, setOptions] = useState([]);
  const [nfceCode, setNfceCode] = useState('');

  const handleSearch = async () => {
    const tamNumped = dataFiltro.numped
      ? dataFiltro.numped.padStart(6, '0')
      : '';
    const tamCodpes = dataFiltro.codpes
      ? dataFiltro.codpes.padStart(5, '0')
      : '';

    let datmov = null;

    const conditionDatmovInicial =
      dataFiltro.datmovInicial !== '' &&
      dataFiltro.datmovInicial !== null &&
      dataFiltro.datmovInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datmovFinal !== '' &&
      dataFiltro.datmovFinal !== null &&
      dataFiltro.datmovFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datmov = [dataFiltro.datmovInicial, dataFiltro.datmovFinal];
    }

    const body = {
      empresa: dataFiltro.empresa || '',
      loja: dataFiltro.loja || '',
      undadm: dataFiltro.undadm || '',
      pedcli: '',
      numped: tamNumped || '',
      sitped: dataFiltro.sitped || '',
      tipped: dataFiltro.tipped || '',
      codpes: tamCodpes || '',
      nomcli: dataFiltro.nomcli || '',
      locped: dataFiltro.locped || '',
      datmov: datmov || '',
      valor: dataFiltro.valor || '',
      fatrdo: dataFiltro.fatrdo || ''
    };

    const conditions = [
      {
        condition: dataFiltro.empresa,
        campo: 'Empresa',
        conteudo: dataFiltro.empresa
      },
      { condition: dataFiltro.loja, campo: 'Loja', conteudo: dataFiltro.loja },
      {
        condition: dataFiltro.undadm,
        campo: 'Und Adm',
        conteudo: dataFiltro.undadm
      },
      { condition: tamNumped, campo: 'Num Ped', conteudo: tamNumped },
      {
        condition: dataFiltro.sitped,
        campo: 'Sit Ped',
        conteudo: dataFiltro.sitped
      },
      {
        condition: dataFiltro.tipped,
        campo: 'Tp Ped',
        conteudo: dataFiltro.tipped
      },
      { condition: tamCodpes, campo: 'Cod Pes', conteudo: tamCodpes },
      {
        condition: dataFiltro.nomcli,
        campo: 'Nom Pes',
        conteudo: dataFiltro.nomcli
      },
      {
        condition: dataFiltro.locped,
        campo: 'Loc Ped',
        conteudo: dataFiltro.locped
      },
      { condition: datmov, campo: 'Dt Mov', conteudo: datmov },
      {
        condition: dataFiltro.valor,
        campo: 'Valor',
        conteudo: dataFiltro.valor
      },
      {
        condition: dataFiltro.fatrdo,
        campo: 'Fatur',
        conteudo: dataFiltro.fatrdo
      }
    ];

    let pesquisasDetalhes = [];

    conditions.forEach(({ condition, campo, conteudo }) => {
      if (condition) pesquisasDetalhes.push({ campo, conteudo });
    });

    setFiltroPesquisa(pesquisasDetalhes);

    try {
      const response = await ApiGet(
        '/pedvenda',
        { pedvenda: body },
        localStorage.getItem('sessionToken')
      );
      if (response.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
        return;
      }

      setData(response.data.pedidoVenda[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const transformeNumber = (value) => {
      if (typeof value === 'string') {
        const formattedValue = value.replace(/\./g, '').replace(',', '.');
        return parseFloat(formattedValue);
      }
      return value;
    };

    const body = {
      empresa: formData?.ped_empresa || '',
      loja: formData?.ped_loja || '',
      undadm: formData?.ped_undadm || '',
      tipped: formData?.ped_tipped || '',
      numped: formData?.ped_numped || '',
      undsol: formData?.ped_undsol || '',
      codpes: formData?.ped_codpes || '',
      pedref: formData?.ped_pedref || '',
      codven: formData?.ped_codven || '',
      codcor: formData?.ped_codcor || '',
      nomcor: formData?.ped_nomcor || '',
      numdoc: formData?.ped_numdoc || '',
      prazo: formData?.ped_prazo || '',
      forpag: formData?.ped_forpag || '',
      tippag: formData?.ped_tippag || '',
      numcot: formData?.ped_numcot || '',
      datmov: formData?.ped_datmov || '',
      datatz: formData?.ped_datatz || '',
      usuario: formData?.ped_usuario || '',
      tabprc: formData?.ped_tabprc || '',
      valor: formData?.ped_valor ? transformeNumber(formData.ped_valor) : '',
      modal: formData?.ped_modal || '',
      valtottrc: formData?.ped_valtottrc
        ? transformeNumber(formData.ped_valtottrc)
        : '',
      valdesc: formData?.ped_valdesc
        ? transformeNumber(formData.ped_valdesc)
        : '',
      valcom: formData?.ped_valcom
        ? transformeNumber(formData.vaped_valcomlor)
        : '',
      horatz: formData?.ped_horatz || '',
      comissao: formData?.ped_comissao || '',
      totnot: formData?.ped_totnot || '',
      pedcli: formData?.ped_pedcli || '',
      numnot: formData?.ped_numnot || '',
      nomcli: formData?.ped_nomcli || '',
      endentr: formData?.ped_endentr || '',
      ufentr: formData?.ped_ufentr || '',
      cidentr: formData?.ped_cidentr || '',
      baientr: formData?.ped_baientr || '',
      prefentr: formData?.ped_prefentr || '',
      cepentr: formData?.ped_cepentr || '',
      endcob: formData?.ped_endcob || '',
      ufcob: formData?.ped_ufcob || '',
      cidcob: formData?.ped_cidcob || '',
      baicob: formData?.ped_baicob || '',
      prefcob: formData?.ped_prefcob || '',
      cepcob: formData?.ped_cepcob || '',
      contato: formData?.ped_contato || '',
      resped: formData?.ped_resped || '',
      valfre: formData?.ped_valfre || '',
      observ: formData?.ped_observ || '',
      valid: formData?.ped_valid || '',
      datrec: formData?.ped_datrec || '',
      datnot: formData?.ped_datnot || '',
      fatrdo: formData?.ped_fatrdo || '',
      codope: formData?.ped_codope || '',
      icms: formData?.ped_icms || '',
      icmsant: formData?.ped_icmsant || '',
      icmsub: formData?.ped_icmsub || '',
      bicms: formData?.ped_bicms || '',
      bicmsant: formData?.ped_bicmsant || '',
      bicmsub: formData?.ped_bicmsub || '',
      ipi: formData?.ped_ipi || '',
      pescom: formData?.ped_pescom || '',
      codban: formData?.ped_codban || '',
      sitped: formData?.ped_sitped || '',
      mrgalt: formData?.ped_mrgalt || '',
      usualtmrg: formData?.ped_usualtmrg || '',
      descper: formData?.ped_descper || '',
      locped: formData?.ped_locped || '',
      datdep: formData?.ped_datdep || '',
      hordep: formData?.ped_hordep || '',
      usudep: formData?.ped_usudep || '',
      numvol: formData?.ped_numvol || '',
      proplic: formData?.ped_proplic || '',
      qtdimp: formData?.ped_qtdimp || '',
      checado: formData?.ped_checado || '',
      codsep: formData?.ped_codsep || '',
      nomsep: formData?.ped_nomsep || '',
      datsep: formData?.ped_datsep || '',
      horsep: formData?.ped_horsep || '',
      numtrcori: formData?.ped_numtrcori || '',
      codctr: formData?.ped_codctr || '',
      codextpv: formData?.ped_codextpv || '',
      consfin: formData?.ped_consfin || '',
      pesobruto: formData?.ped_pesobruto || '',
      pesoliq: formData?.ped_pesoliq || '',
      priori: formData?.ped_priori || '',
      id_ped_ifood: formData?.ped_id_ped_ifood || ''
    };

    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        // response = await NewApiPost(
        //   '/pessoa',
        //   body,
        //   localStorage.getItem('sessionToken')
        // );
      } else {
        response = await ApiPut(
          `/pedvenda`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Cadastro realizado com sucesso');
      } else {
        alert('Atualização realizada com sucesso');
      }
    } catch (error) {
      console.log(error);
    }

    handleReset();
  };
  const handleReset = () => {
    setFormData({});
    setActiveTab(1);
  };
  const handleSendNFCe = async () => {
    try {
      const response = await NewApiPost(
        '/v1/fiscal/nfce/enviar',
        { numeroNFCe: nfceCode },
        localStorage.getItem('sessionToken')
      );

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Nota Fiscal enviada com sucesso');
    } catch (error) {
      console.log(error);
    }
  };

  const handleCombobox = async () => {
    try {
      const response1 = await NewApiGet(
        '/tabela/empresa',
        localStorage.getItem('sessionToken')
      );
      const resEmpresa = response1.data.tabela;

      const response2 = await NewApiGet(
        '/tabela/loja',
        localStorage.getItem('sessionToken')
      );
      const resLoja = response2.data.tabela;

      const response3 = await NewApiGet(
        '/tabela/unidadeAdm',
        localStorage.getItem('sessionToken')
      );
      const resUndAdm = response3.data.tabela;

      const response4 = await NewApiGet(
        '/tabela/formaPag',
        localStorage.getItem('sessionToken')
      );
      const resForPag = response4.data.tabela;

      const response5 = await NewApiGet(
        '/tabela/tipoPag',
        localStorage.getItem('sessionToken')
      );
      const resTipPag = response5.data.tabela;

      setOptions([
        resEmpresa, // 0
        resLoja, // 1
        resUndAdm, // 2
        resForPag, // 3
        resTipPag // 4
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && props.isOpen === 'Menu-PedidoVenda') {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [activeTab, props.isOpen]);

  useEffect(() => {
    handleCombobox();
  }, []);

  const isActiveTabNavOrRel = activeTab === 1 || activeTab === 2;
  const isActiveTabCadOrItems = activeTab === 3 || activeTab === 4;

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-PedidoVenda"
      tabIndex="-1"
      aria-labelledby="lblPedidoVenda"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblPedidoVenda"
              style={{ fontSize: props.tamTitulo }}
            >
              Pedido de Vendas
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>
          <div
            className="offcanvas my-offcanvas rounded"
            tabIndex="-1"
            id="offcanvasFile"
            aria-labelledby="offcanvasFileLabel"
            style={{
              width: '200px',
              height: '200px',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <div className="offcanvas-header d-flex justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <p>Enviar NFCe</p>

              <input
                type="text"
                className="form-control"
                placeholder="Digite o código NFCe"
                value={nfceCode}
                onChange={(e) => setNfceCode(e.target.value)}
                style={{ marginBottom: '1rem' }}
              />
              <button
                className="btn btn-primary"
                style={{ fontSize: props.tamTexto }}
                onClick={handleSendNFCe}
              >
                Enviar NFC-e
              </button>
              <div className="box rounded" style={{ marginTop: '1rem' }}></div>
            </div>
          </div>
          {props.isOpen === 'Menu-PedidoVenda' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasPedVenda"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p
                  className={`nav-link ${
                    activeTab === 1 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(1);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Navegação
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 2 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabNavOrRel) {
                      setActiveTab(2);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabNavOrRel && 'none'
                  }}
                >
                  Relatorio
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 3 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(3);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Cadastro
                </p>
                <p
                  className={`nav-link ${
                    activeTab === 4 ? 'text-primary active' : 'text-dark'
                  }`}
                  onClick={() => {
                    if (isActiveTabCadOrItems) {
                      setActiveTab(4);
                    }
                  }}
                  style={{
                    fontSize: props.tamTexto,
                    display: !isActiveTabCadOrItems && 'none'
                  }}
                >
                  Items
                </p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setDataItems={setDataItems}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                  id="tab2"
                >
                  {data?.length >= 1 && (
                    <Relatorio data={data} filtroPesquisa={filtroPesquisa} />
                  )}
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                    options={options}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                  id="tab4"
                >
                  <GridItems
                    dataItems={dataItems}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasFile"
                  aria-controls="offcanvasFile"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-file"></i>
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasPedVenda"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(3);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 3 && (
              <>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button> */}
                <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
