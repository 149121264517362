import { NumericFormat } from 'react-number-format';

export const CadItem = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Cód. Item</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Unid.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tipo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_item"
              value={props.formData?.itm_item || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              readOnly
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_unidade"
              value={props.formData?.itm_unidade || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_tipo"
              value={props.formData?.itm_tipo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Marca</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Grupo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="item_marca"
              value={props.formData?.item_marca || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_grupo"
              value={props.formData?.itm_grupo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Embal.</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Subgrupo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_embala"
              value={props.formData?.itm_embala || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_subgrupo"
              value={props.formData?.itm_subgrupo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Descrição</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_descri"
              value={props.formData?.itm_descri || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Desc. Téc.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_destec"
              value={props.formData?.itm_destec || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">NCM</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">CEST.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Modelo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_ncm"
              value={props.formData?.itm_ncm || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_cest"
              value={props.formData?.itm_cest || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_modelo"
              value={props.formData?.itm_modelo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Origem</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_orinfe"
              value={props.formData?.itm_orinfe || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Remarcação</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Vl. Varejo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_dremarc"
              value={props.formData?.itm_dremarc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <NumericFormat
              value={props.formData.itm_valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  itm_valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Clas. Fiscal</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Vl. Atacado</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="itm_clasfisc"
              value={props.formData?.itm_clasfisc || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  itm_clasfisc: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[0]?.map((option, index) => (
                <option key={index} value={option.fis_codfis}>
                  {option.fis_descri}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <NumericFormat
              value={props.formData.itm_valormin || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  itm_valormin: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Margem</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Com. Extra</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Custo Fin.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_codmrg"
              value={props.formData?.itm_codmrg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_comext"
              value={props.formData?.itm_comext || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.itm_custfin || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  itm_custfin: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Taxa de Serviço</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Custo Operacional</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <NumericFormat
              value={props.formData.itm_taxser || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  itm_taxser: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-6">
            <NumericFormat
              value={props.formData.itm_custopr || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  itm_custopr: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-12">
          <fieldset className="border p-2" style={{ borderColor: '#d3d3d3' }}>
            <legend
              className="float-none w-auto px-2"
              style={{ fontSize: '1rem', color: '#6c757d' }}
            >
              Promoção
            </legend>
            <div className="row">
              <div className="col-6">
                <span className="col-form-label">Validade</span>
              </div>
              <div className="col-6">
                <span className="col-form-label">Valor</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="date"
                  className="form-control form-control-sm border border-dark"
                  name="itm_validprom"
                  value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view'}
                />
              </div>
              <div className="col-6">
                <NumericFormat
                  value={props.formData.itm_valprom || ''}
                  onValueChange={(values) => {
                    const { formattedValue } = values;
                    props.setFormData({
                      ...props.formData,
                      itm_valprom: formattedValue
                    });
                  }}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  className="form-control form-control-sm border border-dark"
                  style={{ textAlign: 'right' }}
                  disabled={props.viewOrUpdate === 'view'}
                  suffix={
                    props.value % 1 === 0
                      ? ',00'
                      : (props.value * 10) % 1 === 0
                      ? '0'
                      : ''
                  }
                />
              </div>
            </div>
          </fieldset>
        </div>

        <div className="col-12 mt-1">
          <fieldset className="border p-2" style={{ borderColor: '#d3d3d3' }}>
            <div className="row">
              <div className="col-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                    // onChange={props.handleInput}
                    disabled={props.viewOrUpdate === 'view'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Almoxarifado
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                    // onChange={props.handleInput}
                    disabled={props.viewOrUpdate === 'view'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Cardápio
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                    // onChange={props.handleInput}
                    disabled={props.viewOrUpdate === 'view'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Geral
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};
