import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fLogin } from '../functions';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
  .object({
    user: yup.string().required('Campo Obrigatorio'),
    password: yup.string().required('Campo Obrigatorio')
  })
  .required();

export const CLogin = (props) => {
  const { id } = useParams();
  const idFormat = id.replace('recep', '');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [btnColor, setBtnColor] = useState('btn-danger');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await fLogin(
      data.user,
      data.password,
      props.dataBanco,
      id,
      props.dataEmpresa
    );

    if (!response) {
      setLoading(false);
      setBtnColor('btn-danger');
      return;
    }

    if (response) {
      setLoading(false);
      props.setLogin(true);
      setBtnColor('btn-secondary disabled');
    } else {
      setLoading(false);
      setBtnColor('btn-danger');
    }
  };

  useEffect(() => {
    props.handleEmpresa(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ width: '100%', height: '100vh' }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center">
            <img src={`imagens/${idFormat}/logo.png`} alt="logo" width={150} />
          </div>
          <div className="mb-3">
            <label htmlFor="input_user">{t('Email/Usuario')}</label>
            <div>
              <input
                type="text"
                id="input_user"
                {...register('user')}
                className="form-control border border-dark"
              />
              <p className="text-danger">{errors.user?.message}</p>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="input_password">{t('Senha')}</label>
            <div>
              <input
                type="password"
                id="input_password"
                {...register('password')}
                className="form-control border border-dark"
              />
              <p className="text-danger">{errors.password?.message}</p>
            </div>
          </div>

          <div>
            <input
              className={`w-100 btn ${btnColor}`}
              type="submit"
              value={loading ? `${t('Enviando')}...` : t('Acessar')}
            />
          </div>
        </form>
      </div>

      <div className="position-relative">
        <div className="position-absolute bottom-0 end-0 d-flex flex-column">
          <p className="nav-link text-end me-2" style={{ fontSize: '0.8em' }}>
            V 1.03.53
          </p>
          <img src="imagens/logo.png" width={150} />
        </div>
      </div>
    </>
  );
};
