import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const GridCaixa = (props) => {
  const campos = [
    { title: 'Emp.', order: 'cxa_codemp' },
    { title: 'Loja', order: 'cxa_codloj' },
    { title: 'Und. Adm.', order: 'cxa_coduad' },
    { title: 'N. Cx', order: 'cxa_numcai' },
    { title: 'Cód. Cx', order: 'cxa_codcai' },
    { title: 'Descrição', order: 'cxa_descai' },
    { title: 'Dt. Abert.', order: 'cxa_databr' },
    { title: 'Dt. Fecham.', order: 'cxa_datfec' }
  ];

  const [dataFilter, setDataFilter] = useState({
    numcai: '',
    codcai: ''
  });

  const [filteredData, setFilteredData] = useState([]);

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'cxa_codemp':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_codemp.localeCompare(b.cxa_codemp)
          )
        );
        break;
      case 'cxa_codloj':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_codloj.localeCompare(b.cxa_codloj)
          )
        );
        break;
      case 'cxa_coduad':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_coduad.localeCompare(b.cxa_coduad)
          )
        );
        break;
      case 'cxa_numcai':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_numcai.localeCompare(b.cxa_numcai)
          )
        );
        break;
      case 'cxa_codcai':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_codcai.localeCompare(b.cxa_codcai)
          )
        );
        break;
      case 'cxa_descai':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_descai.localeCompare(b.cxa_descai)
          )
        );
        break;
      case 'cxa_databr':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_databr.localeCompare(b.cxa_databr)
          )
        );
        break;
      case 'cxa_datfec':
        props.setDataCaixa(
          [...props.dataCaixa].sort(
            (a, b) => multiplier * a.cxa_datfec.localeCompare(b.cxa_datfec)
          )
        );
        break;
      default:
        props.setDataCaixa([...props.dataCaixa]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.dataCaixa);
  }, [props.dataCaixa]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Número do Caixa</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={dataFilter?.numcai || ''}
              onChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  numcai: e.target.value
                })
              }
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Código do Caixa</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={dataFilter?.codcai || ''}
              onChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  codcai: e.target.value
                })
              }
            />
          </div>
        </div>
      </div>

      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Escolher
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData
            ?.filter((item) => {
              if (dataFilter.numcai !== '') {
                if (dataFilter.codcai !== '') {
                  return (
                    item.cxa_codcai === dataFilter.codcai &&
                    item.cxa_numcai === dataFilter.numcai.padStart(5, '0')
                  );
                }
                return item.cxa_numcai === dataFilter.numcai.padStart(5, '0');
              }

              if (dataFilter.codcai !== '') {
                return item.cxa_codcai === dataFilter.codcai;
              }

              return item;
            })
            .filter((item) => item.cxa_datfec.slice(0, 10) === '1899-12-30')
            .map((item, index) => (
              <tr key={index}>
                <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_codemp}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_codloj}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_coduad}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_numcai}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_codcai}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_descai}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {format(new Date(item.cxa_databr), 'dd/MM/yyyy')}
                </td>
                <td style={{ fontSize: props.tamSubTexto }}>
                  {item.cxa_datfec.slice(0, 10) !== '1899-12-30'
                    ? format(new Date(item.cxa_datfec), 'dd/MM/yyyy')
                    : ''}
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      style={{ fontSize: props.tamSubTexto }}
                      onClick={() => {
                        props.setFormDataLiquida({
                          ...props.formDataLiquida,
                          cnt_numcax: item.cxa_numcai
                        });
                        props.setActiveTab(5);
                      }}
                    >
                      <i className="fa-solid fa-check"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
