import { NumericFormat } from 'react-number-format';

export const CadPedidos = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        {/* <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="ped_empresa"
              value={props.formData?.ped_empresa || ''}
              onChange={(e) =>
                props.setFormData({
                  ...props.formData,
                  ped_empresa: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[0]?.map((option, index) => (
                <option key={index} value={option.emp_codemp}>
                  {option.emp_codemp} - {option.emp_fantasia}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Loja</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Pedido</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Nr. Col</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="ped_loja"
              value={props.formData?.ped_loja || ''}
              onChange={(e) =>
                props.setFormData({
                  ...props.formData,
                  ped_loja: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.loj_codloj}>
                  {option.loj_codloj} - {option.loj_denloj}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_codpes"
              // value={props.formData?.ped_codpes || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_codpes"
              // value={props.formData?.ped_codpes || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        {/* <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Und. Aministrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="ped_undadm"
              value={props.formData?.ped_undadm || ''}
              onChange={(e) =>
                props.setFormData({
                  ...props.formData,
                  ped_undadm: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[2]
                ?.slice()
                .sort((a, b) =>
                  a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
                )
                .map((option, index) => (
                  <option key={index} value={option.codigo_unidade_adm}>
                    {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                  </option>
                ))}
            </select>
          </div>
        </div> */}

        <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label">Vendedor</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Comissão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_codpes"
              // value={props.formData?.ped_codpes || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label">Cliente</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">N. P Client.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_codpes"
              // value={props.formData?.ped_codpes || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-7">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-8">
            <span className="col-form-label">Zona</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Valid</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Pz. Ent.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_codpes"
              // value={props.formData?.ped_codpes || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label"></span>
          </div>
          <div className="col-3">
            <span className="col-form-label">N. Contrato</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2"></div>
          <div className="col-7"></div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="ped_nomcli"
              // value={props.formData?.ped_nomcli || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Observação</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_observ"
              value={props.formData?.ped_observ || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Val Id</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. Ope</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Descper</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_valid"
              value={props.formData?.ped_valid || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_codope"
              value={props.formData?.ped_codope || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_descper"
              value={props.formData?.ped_descper || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Prazo</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Dat Rec</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ped_prazo"
              value={props.formData?.ped_prazo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ped_datrec"
              value={props.formData?.ped_datrec?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Valor</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Val Desc</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Val Tot Trc</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valor || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valor: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valdesc || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valdesc: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
          <div className="col-4">
            <NumericFormat
              value={props.formData.ped_valtottrc || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormData({
                  ...props.formData,
                  ped_valtottrc: formattedValue
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
