import { NumericFormat } from 'react-number-format';

export const CadConsumo = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Nr. Consumo</span>
          </div>
          <div className="col-6">
            <span className="col-form-label"></span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Dat. Mov.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroConsumo"
              value={props.formData?.numeroConsumo || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <fieldset className="border p-2" style={{ borderColor: '#d3d3d3' }}>
              <div className="row">
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                      // onChange={props.handleInput}
                      disabled={props.viewOrUpdate === 'view'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Apartamento
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                      // onChange={props.handleInput}
                      disabled={props.viewOrUpdate === 'view'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Mesa
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      // value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                      // onChange={props.handleInput}
                      disabled={props.viewOrUpdate === 'view'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Balcão
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="numeroConsumo"
              // value={props.formData?.numeroConsumo || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">Setor Abert.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Setor Lancto.</span>
          </div>
          <div className="col-2">
            <span className="col-form-label">Nr. Apto.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Nr. Cartela</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Dat. Fechamento</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroCarga"
              value={props.formData?.numeroCarga || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroCarga"
              value={props.formData?.numeroCarga || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroCarga"
              value={props.formData?.numeroCarga || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroCarga"
              value={props.formData?.numeroCarga || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="numeroSaida"
              // value={props.formData?.numeroSaida || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-9">
            <span className="col-form-label">Garçom</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Nr. Saída</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="ped_empresa"
                  value={props.formData?.ped_empresa || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view' ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="numeroSaida"
              // value={props.formData?.numeroSaida || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
