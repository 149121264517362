export function CadOutrosDados(props) {
  return (
    <div>
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Situação de Crédito</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Limite de credito</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_sitcre"
              value={props.formData?.pes_sitcre || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_sitcre: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[6]?.map((option, index) => (
                <option key={index} value={option.tsc_codsit}>
                  {option.tsc_dessit}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_codcre"
              value={props.formData?.pes_codcre || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_codcre: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[7]?.map((option, index) => (
                <option key={index} value={option.cre_codcre}>
                  {option.cre_valor}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Grupo Credito</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Codogo da Rota</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_grpcred"
              value={props.formData?.pes_grpcred || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_grpcred: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[8]?.map((option, index) => (
                <option key={index} value={option.tgc_grpcred}>
                  {option.tgc_descri}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <select
              className="form-select form-select-sm border border-dark"
              name="pes_codrota"
              value={props.formData?.pes_codrota || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  pes_codrota: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[9]?.map((option, index) => (
                <option key={index} value={option.rot_codrot}>
                  {option.rot_descri}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Vendedor</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 d-flex gap-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="pes_codven"
              value={props.formData?.pes_codven || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setActiveTabCadastro(9);
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <i className="fa-regular fa-file"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
