import { useState } from 'react';

import { NewApiGet } from '../../../services/apiService';
import { CadClientes } from './clientes/CadClientes';
import { GridVendedor } from './clientes/GridVendedor';
import { CadCorretor } from './corretor/CadCorretor';
import { CadFornecedor } from './fornecedor/CadFornecedor';
import { CadFuncionario } from './funcionario/CadFuncionario';
import { CadTecnico } from './tecnico/CadTecnico';
import { CadTipoPessoa } from './tipoPessoa/CadTipoPessoa';
import { CadTransportador } from './transportador/CadTransportador';
import { CadVendedor } from './vendedor/CadVendedor';

export function Cadastro(props) {
  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  const handleCep = async (event) => {
    const id = event.target.value;
    const id_format = id.replace(/\D/g, '');

    try {
      const response = await NewApiGet(
        `/local/cep/${id_format}`,
        localStorage.getItem('sessionToken')
      );
      const cep = response.data.cep[0];

      props.setFormData({
        ...props.formData,
        id_estado: cep?.ces_iduf || props.formData?.id_estado,
        pes_uf: cep?.ces_coduf || props.formData?.pes_uf,
        pes_iduf: cep?.ces_iduf || props.formData?.pes_iduf,
        pes_cidade: cep?.ces_nomcidade || props.formData?.pes_cidade,
        id_cidade: cep?.ces_cidadeibge || props.formData?.id_cidade,
        pes_cidresibge: cep?.ces_cidadeibge || props.formData?.id_cidade,
        pes_bairro: cep?.ces_bairro ? cep.ces_bairro : '',
        pes_endere: cep?.ces_endereco ? cep.ces_endereco : '',
        pes_comple: cep?.ces_complemento ? cep.pes_comple : ''
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p
          // className={`nav-link ${
          //   props.activeTabCadastro === 1 ? 'text-primary active' : 'text-dark'
          // }`}
          // onClick={() => setActiveTab(1)}
          // style={{ fontSize: props.tamTexto }}
          style={{ display: 'none' }}
        >
          Dados
        </p>
        <p style={{ display: 'none' }}>Clientes</p>
        <p style={{ display: 'none' }}>Corretor</p>
        <p style={{ display: 'none' }}>Fornecedores</p>
        <p style={{ display: 'none' }}>Vendedor</p>
        <p style={{ display: 'none' }}>Funcionário</p>
        <p style={{ display: 'none' }}>Técnico</p>
        <p style={{ display: 'none' }}>Transportador</p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 1 ? 'active' : ''
          }`}
          id="tabCadastro1"
        >
          <CadTipoPessoa
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            options={props.options}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 2 ? 'active' : ''
          }`}
          id="tabCadastro2"
        >
          <CadClientes
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
            options={props.options}
            insertOrUpdate={props.insertOrUpdate}
            handleCep={handleCep}
            setActiveTabCadastro={props.setActiveTabCadastro}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 3 ? 'active' : ''
          }`}
          id="tabCadastro3"
        >
          <CadCorretor
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 4 ? 'active' : ''
          }`}
          id="tabCadastro4"
        >
          <CadFornecedor
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 5 ? 'active' : ''
          }`}
          id="tabCadastro5"
        >
          <CadVendedor
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 6 ? 'active' : ''
          }`}
          id="tabCadastro6"
        >
          <CadFuncionario
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 7 ? 'active' : ''
          }`}
          id="tabCadastro7"
        >
          <CadTecnico
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 8 ? 'active' : ''
          }`}
          id="tabCadastro8"
        >
          <CadTransportador
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 9 ? 'active' : ''
          }`}
          id="tabCadastro9"
        >
          <GridVendedor
            setActiveTabCadastro={props.setActiveTabCadastro}
            formData={props.formData}
            setFormData={props.setFormData}
          />
        </div>
      </div>
    </>
  );
}
