import { useEffect, useState } from 'react';

import { formatRequestArray } from '../../../../functions/formatRequestArray';
import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { ApiGet } from '../../../../services/apiService';
import { ChartPieQt } from '../../graficos/ChartPieQt';
import { TabelaDinamica } from '../../graficos/TabelaDinamica';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';

export const DashboardContabil = () => {
  const [viewData, setViewData] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [contaContabil, setcontaContabil] = useState([]);
  const [contaContabilR, setcontaContabilR] = useState([]);
  const [ccRLista, setCcRLista] = useState([]);
  const [cc, setCc] = useState('');
  const [ccLista, setCcLista] = useState([]);
  const { startDate: sdate, endDate: edate } = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);
  const [tipoPeriodo, setTipoPeriodo] = useState('pagamento');
  const handleSetTipoPeriodo = (selectedOption) => {
    setTipoPeriodo(selectedOption.codigo);
  };
  const [realizada, setRealizada] = useState(2);
  const handleSetRealizada = (selectedOption) => {
    setRealizada(selectedOption.codigo);
  };

  useEffect(() => {
    const listaConta = contaContabil.map((item) => item.codigo);
    const dataInicial = startDate.replace(/-/g, '').slice(0, 6);
    const dataFinal = endDate.replace(/-/g, '').slice(0, 6);
    const cct = formatRequestArray(cc, 1);
    const ccR = formatRequestArray(contaContabilR, 1);
    const requests = [
      ApiGet(
        `/v1/graficos/contabil/vi_contas/tabela/ccDataValor`,
        {
          startDate: dataInicial,
          endDate: dataFinal,
          conta: listaConta,
          realizado: realizada,
          tipoData: tipoPeriodo,
          centroCusto: cct,
          contaRazao: ccR
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/v1/graficos/contabil/vi_contas/pizza/contaXvalor`,
        {
          startDate: dataInicial,
          endDate: dataFinal,
          conta: listaConta,
          realizado: realizada,
          tipoData: tipoPeriodo,
          centroCusto: cct,
          contaRazao: ccR
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/v1/graficos/contabil/vi_contas/tabela/ccDataValorPos`,
        {
          startDate: dataInicial,
          endDate: dataFinal,
          conta: listaConta,
          posicao: 5,
          realizado: realizada,
          tipoData: tipoPeriodo,
          centroCusto: cct,
          contaRazao: ccR
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        `/v1/graficos/contabil/vi_contas/pizza/contaXvalorPos`,
        {
          startDate: dataInicial,
          endDate: dataFinal,
          conta: listaConta,
          posicao: 5,
          realizado: realizada,
          tipoData: tipoPeriodo,
          centroCusto: cct,
          contaRazao: ccR
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        const dados = responses.map((response) => {
          return response.data.dados.map((item) => {
            return {
              ...item,
              data: `${String(item.mes_ano).slice(0, 4)}-${String(
                item.mes_ano
              ).slice(4, 6)}`,
              sum: item.valor_despesa
                ? parseFloat(item.valor_despesa).toFixed(2)
                : null,
              avg: item.valor_pago
                ? parseFloat(item.valor_pago).toFixed(2)
                : null,
              nome: item.tpc_descri
            };
          });
        });
        setViewData(dados);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    startDate,
    endDate,
    contaContabil,
    tipoPeriodo,
    realizada,
    cc,
    contaContabilR
  ]);

  useEffect(() => {
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: [
          'cnt_codcont as codigo',
          '(SELECT tpc_descri FROM tab_plano_conta WHERE tpc_codpcn = cnt_codcont) as nome'
        ],
        where: { cnt_damov: { gt: '2024-01-01' } },
        groupBy: 'cnt_codcont',
        order: { column: 'nome', isAscending: true }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return { item: item.nome, codigo: item.codigo };
        });
        setEmpresa(dados);
      })
      .catch((error) => {
        console.log('Request fitlro contas contabil analitica', error);
      });

    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: [
          'cnt_cencus as codigo',
          '(select tcc_nomtcc from tab_cct where tcc_codtcc = cnt_cencus)as nome'
        ],
        groupBy: 'cnt_cencus',
        order: { column: 'nome', isAscending: true }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados
          .map((item) => {
            // if (!item.nome) return null;
            return {
              item: item.nome || item.codigo + ' - NAO REGISTRADO',
              codigo: item.codigo,
              value: item.codigo
            };
          })
          .filter(Boolean);
        setCcLista(dados);
      })
      .catch((error) => {
        console.log('Request fitlro centro custo', error);
      });
    ApiGet(
      `/view`,
      {
        view: 'vi_contas',
        select: [
          `RPAD(substr(cnt_codcont, 1, 5), 8, '0') as codigo`,
          `(SELECT tpc_descri FROM tab_plano_conta WHERE tpc_codpcn = RPAD(substr(cnt_codcont, 1, 5), 8, '0')) as nome`
        ],
        where: { cnt_damov: { gt: '2024-01-01' } },
        groupBy: `codigo, nome`,
        order: { column: 'nome', isAscending: true }
      },
      localStorage.getItem('sessionToken')
    )
      .then((response) => {
        const dados = response.data.dados.map((item) => {
          return {
            item:
              item.nome + ' - ' + item.codigo ||
              item.codigo + ' - NAO REGISTRADO',
            codigo: item.codigo
          };
        });
        setCcRLista(dados);
      })
      .catch((error) => {
        console.log('Request fitlro contas contabil razao', error);
      });
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Contábil</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          title1={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Inicial'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Inicial'
              : 'Data Mov Incial'
          }
          title2={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Final'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Final'
              : 'Data Mov Final'
          }
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="Centro Custo"
          listaItems={ccLista}
          value={cc}
          setValue={setCc}
        />
        <FiltroDropdown
          title="Conta Contabil - Razão"
          listaItems={ccRLista}
          value={contaContabilR}
          setValue={setcontaContabilR}
        />
        <FiltroDropdown
          title="Conta Contabil - Analítica"
          listaItems={empresa}
          value={contaContabil}
          setValue={setcontaContabil}
        />
        <FiltroDropdown
          title="Realizada"
          listaItems={[
            { item: 'Todos', codigo: 0 },
            { item: 'A realizar', codigo: 1 },
            { item: 'Realizada', codigo: 2 }
          ]}
          value={realizada}
          setValue={handleSetRealizada}
          isDate={true}
        />
        <FiltroDropdown
          title="Tipo Periodo"
          listaItems={[
            { item: 'pagamento', codigo: 'pagamento' },
            { item: 'vencimento', codigo: 'vencimento' },
            { item: 'movimento(competência)', codigo: 'movimento' }
          ]}
          value={tipoPeriodo}
          setValue={handleSetTipoPeriodo}
          isDate={true}
        />
      </div>
      {viewData.length > 0 && (
        <div className="w-100 h-100">
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start text-center w-100 h-100">
            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {viewData[3] && (
                <ChartPieQt
                  data={viewData[3]}
                  titulo="Grafico Razão"
                  isMonetary={true}
                />
              )}
            </div>

            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {viewData[2] && (
                <TabelaDinamica
                  data={viewData[2]}
                  headers={[
                    { label: 'Data', value: 'data' },
                    {
                      label: 'Conta Contabil',
                      value: 'cnt_codcont',
                      align: 'left'
                    },
                    {
                      label: 'Conta Descrição',
                      value: 'tpc_descri',
                      align: 'left'
                    },
                    {
                      label: 'Valor Despesa',
                      value: 'soma_cnt_valdps',
                      align: 'right',
                      type: 'monetary'
                    },

                    {
                      label: 'Valor Pago',
                      value: 'soma_cnt_valpag',
                      align: 'right',
                      type: 'monetary'
                    }
                  ]}
                  title="Tabela Razão"
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start text-center w-100 h-100">
            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {
                <ChartPieQt
                  data={viewData[1]}
                  titulo="Gráfico Analítico de contas"
                  isMonetary={true}
                />
              }
            </div>

            <div className="w-75 h-100 d-flex justify-content-center align-items-center align-items-md-start">
              {viewData[0] && (
                <TabelaDinamica
                  data={viewData[0]}
                  headers={[
                    { label: 'Data', value: 'data' },
                    {
                      label: 'Conta Contabil',
                      value: 'cnt_codcont',
                      align: 'left'
                    },
                    {
                      label: 'Conta Descrição',
                      value: 'tpc_descri',
                      align: 'left'
                    },
                    {
                      label: 'Valor Despesa',
                      value: 'soma_cnt_valdps',
                      align: 'right',
                      type: 'monetary'
                    },

                    {
                      label: 'Valor Pago',
                      value: 'soma_cnt_valpag',
                      align: 'right',
                      type: 'monetary'
                    }
                  ]}
                  title="Contas Analíticas"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
