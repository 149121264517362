export const CadComplemento = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Situação do Item</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_sititm"
              value={props.formData?.itm_sititm || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Item fora da Tabela</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              // name="item_marca" //TODO: Falta Item fora da Tabela
              // value={props.formData?.item_marca || ''}
              // onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Qtd. Mín. p/ Atacado</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_qtdatc"
              value={props.formData?.itm_qtdatc || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Venda no Varejo</span>{' '}
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.formData?.pes_pednfe === 'S' ? true : false}
              onChange={() => {
                props.setFormData({
                  ...props.formData,
                  pes_pednfe: props.formData?.pes_pednfe === 'S' ? 'N' : 'S'
                });
              }}
              id="flexCheckDefault"
            />
          </div>
          <div className="col-6">
            <span className="col-form-label">Possui Sub-Item</span>{' '}
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.formData?.pes_pednfe === 'S' ? true : false}
              onChange={() => {
                props.setFormData({
                  ...props.formData,
                  pes_pednfe: props.formData?.pes_pednfe === 'S' ? 'N' : 'S'
                });
              }}
              id="flexCheckDefault"
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Item Composto</span>{' '}
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.formData?.pes_pednfe === 'S' ? true : false}
              onChange={() => {
                props.setFormData({
                  ...props.formData,
                  pes_pednfe: props.formData?.pes_pednfe === 'S' ? 'N' : 'S'
                });
              }}
              id="flexCheckDefault"
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-8">
            <span className="col-form-label">Tipo de Insumo</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-8">
            <select
              className="form-select form-select-sm border border-dark"
              name="itm_codtins"
              value={props.formData?.itm_codtins || ''}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  itm_codtins: e.target.value
                });
              }}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.tin_codtins}>
                  {option.tin_nomtins}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
        <div className="col-12 row">
          <fieldset className="border p-2" style={{ borderColor: '#d3d3d3' }}>
            <legend
              className="float-none w-auto px-2"
              style={{ fontSize: '1rem', color: '#6c757d' }}
            >
              Localização do Item
            </legend>
            <div className="row">
              <div className="col-6">
                <span className="col-form-label">Rua</span>
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="itm_validprom"
                  value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <span className="col-form-label">Coluna</span>
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="itm_validprom"
                  value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view'}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <span className="col-form-label">Prateleira</span>
                <input
                  type="text"
                  className="form-control form-control-sm border border-dark"
                  name="itm_validprom"
                  value={props.formData?.itm_validprom?.slice(0, 10) || ''}
                  onChange={props.handleInput}
                  disabled={props.viewOrUpdate === 'view'}
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Fabricante</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_codfab"
              value={props.formData?.itm_codfab || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_codfab"
              value={props.formData?.itm_codfab || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">N. Nf Últ. Compra</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. Cadastro</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_nfult"
              value={props.formData?.itm_nfult || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_datmov"
              value={props.formData?.itm_datmov?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Val Item na NF</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_valornf"
              value={props.formData?.itm_valornf || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_usuario"
              value={props.formData?.itm_usuario || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label"></span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Dt. Alteração</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4"></div>
          <div className="col-4">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="itm_datatz"
              value={props.formData?.itm_datatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label"></span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Hora</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4"></div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="itm_horatz"
              value={props.formData?.itm_horatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
