import { useState } from 'react';

import { CadCadastroClientes } from './CadCadastroClientes';
import { CadDadosComplementares } from './CadDadosComplementares';
import { CadDadosConjuge } from './CadDadosConjuge';
import { CadInfoBancario } from './CadInfoBancario';
import { CadObservacao } from './CadObservacao';
import { CadOutrosDados } from './CadOutrosDados';
import { CadTelefones } from './CadTelefones';

export function CadClientes(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab3, setActiveTab3] = useState(1);

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab3 === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab3(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Cadastro de Clientes
          </p>
          <p
            className={`nav-link ${
              activeTab3 === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab3(2)}
            style={{ fontSize: props.tamTexto }}
          >
            Outros Dados
          </p>
          <p
            className={`nav-link ${
              activeTab3 === 3 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab3(3)}
            style={{ fontSize: props.tamTexto }}
          >
            Dados Complementares
          </p>
          {/* <p
            className={`nav-link ${
              activeTab3 === 4 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab3(4)}
            style={{ fontSize: props.tamTexto }}
          >
            Dados do Cônjuge
          </p> */}
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab3 === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadCadastroClientes
              handleInput={props.handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
              options={props.options}
              insertOrUpdate={props.insertOrUpdate}
              handleCep={props.handleCep}
            />
          </div>
          <div
            className={`tab-pane ${activeTab3 === 2 ? 'active' : ''}`}
            id="tabCadastro2"
          >
            <CadOutrosDados
              handleInput={props.handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
              options={props.options}
              setActiveTabCadastro={props.setActiveTabCadastro}
            />
          </div>
          <div
            className={`tab-pane ${activeTab3 === 3 ? 'active' : ''}`}
            id="tabCadastro3"
          >
            <CadDadosComplementares
              handleInput={props.handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
          {/* <div
            className={`tab-pane ${activeTab3 === 4 ? 'active' : ''}`}
            id="tabCadastro4"
          >
            <CadDadosConjuge
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div> */}
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Telefones
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ fontSize: props.tamTexto }}
          >
            Inf. Bancárias
          </p>
          <p
            className={`nav-link ${
              activeTab === 3 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(3)}
            style={{ fontSize: props.tamTexto }}
          >
            Observações
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadTelefones
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
              setFormData={props.setFormData}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tabCadastro2"
          >
            <CadInfoBancario
              handleInput={props.handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
              options={props.options}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
            id="tabCadastro3"
          >
            <CadObservacao
              handleInput={props.handleInput}
              formData={props.formData}
              viewOrUpdate={props.viewOrUpdate}
              setFormData={props.setFormData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
