import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

export const Liquidar = (props) => {
  const [dateAtual] = useState(() => {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0');
    const dia = String(hoje.getDate()).padStart(2, '0');

    props.setFormDataLiquida({
      ...props.formDataLiquida,
      cnt_datpag:
        (props.formDataLiquida?.cnt_datpag?.slice(0, 10) !== '1899-12-30' &&
          props.formDataLiquida?.cnt_datpag) ||
        `${ano}-${mes}-${dia}`,
      cnt_datbai: `${ano}-${mes}-${dia}`
    });

    return `${ano}-${mes}-${dia}`;
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormDataLiquida({ ...props.formDataLiquida, [name]: value });
  };

  const valueDol = (moe__codigo) => {
    const today = new Date().toISOString().split('T')[0];

    const filtroDolar = props.options[13].filter((option) => {
      const dolData = option.dol_data.split('T')[0];
      return option.dol_tipmoe === moe__codigo.slice(0, 3) && dolData === today;
    });

    props.setFormDataLiquida({
      ...props.formDataLiquida,
      cnt_valmoe:
        parseFloat(
          String(props.formDataLiquida?.cnt_valpag).includes(',')
            ? String(props.formDataLiquida?.cnt_valpag)
                ?.replace(/\./g, '')
                .replace(',', '.')
            : parseFloat(props.formDataLiquida?.cnt_valpag)
        ) / (filtroDolar[0]?.dol_valor || 1),
      cnt_cambio: filtroDolar[0]?.dol_valor || 0,
      cnt_codmoe: moe__codigo
    });

    return filtroDolar;
  };

  const formatNumber = (value) => {
    if (typeof value === 'string') {
      return parseFloat(
        String(value)?.replace(/\./g, '').replace(',', '.') || 0
      );
    } else {
      return parseFloat(value);
    }
  };

  useEffect(() => {
    if (props.activeTab === 5) {
      props.setFormDataLiquida({
        ...props.formDataLiquida,
        cnt_datpag: dateAtual,
        cnt_datbai: dateAtual,
        cnt_valpag:
          parseFloat(props.formDataLiquida?.cnt_valdps || 0) +
          parseFloat(props.formDataLiquida?.cnt_juros || 0) +
          parseFloat(props.formDataLiquida?.cnt_multa || 0) -
          parseFloat(props.formDataLiquida?.cnt_descon || 0)
      });
    }
  }, [props.activeTab]);

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Tipo de Pagamento</span>
          <div>
            <select
              className="form-select form-select-sm border border-dark"
              value={props.formDataLiquida?.cnt_tippag || ''}
              onChange={(e) =>
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_tippag: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[7]?.map((option, index) => (
                <option key={index} value={option.tpg_tippag}>
                  {option.tpg_destpg}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Doc. Pagto.</span>
          <div>
            <select
              className="form-select form-select-sm border border-dark"
              value={props.formDataLiquida?.cnt_tipdoc || ''}
              onChange={(e) =>
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_tipdoc: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[11]?.map((option, index) => (
                <option key={index} value={option.doc_coddoc}>
                  {option.doc_desdoc}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Valor</span>
          <div>
            <NumericFormat
              value={props.formDataLiquida.cnt_valdps || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_valdps: formattedValue
                });
              }}
              onBlur={() => {
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_valpag:
                    formatNumber(props.formDataLiquida?.cnt_valdps || 0) +
                    formatNumber(props.formDataLiquida?.cnt_juros || 0) +
                    formatNumber(props.formDataLiquida?.cnt_multa || 0) -
                    formatNumber(props.formDataLiquida?.cnt_descon || 0)
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Tipo Cartão</span>
          <div>
            <select
              className="form-select form-select-sm border border-dark"
              value={props.formDataLiquida?.cnt_codcar || ''}
              onChange={(e) =>
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_codcar: e.target.value
                })
              }
            >
              <option defaultValue></option>
              {props.options[12]?.map((option, index) => (
                <option key={index} value={option.ccr_codcar}>
                  {option.ccr_nomcar}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Juros</span>
          <div>
            <NumericFormat
              value={props.formDataLiquida.cnt_juros || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_juros: formattedValue
                });
              }}
              onBlur={() => {
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_valpag:
                    formatNumber(props.formDataLiquida?.cnt_valdps || 0) +
                    formatNumber(props.formDataLiquida?.cnt_juros || 0) +
                    formatNumber(props.formDataLiquida?.cnt_multa || 0) -
                    formatNumber(props.formDataLiquida?.cnt_descon || 0)
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">N. Doc. Pag.</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.formDataLiquida?.cnt_docpag || ''}
              name="cnt_docpag"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Multa</span>
          <div>
            <NumericFormat
              value={props.formDataLiquida.cnt_multa || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_multa: formattedValue
                });
              }}
              onBlur={() => {
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_valpag:
                    formatNumber(props.formDataLiquida?.cnt_valdps || 0) +
                    formatNumber(props.formDataLiquida?.cnt_juros || 0) +
                    formatNumber(props.formDataLiquida?.cnt_multa || 0) -
                    formatNumber(props.formDataLiquida?.cnt_descon || 0)
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Banco</span>
          <div>
            <select
              className="form-select form-select-sm border border-dark"
              value={props.formDataLiquida?.cnt_codban || ''}
              onChange={(e) => {
                const filterBanco = props.options[9].filter(
                  (option) => option.ban_codban === e.target.value
                );

                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_codban: e.target.value,
                  ban_agencia: filterBanco[0]?.ban_agencia || '',
                  ban_conta: filterBanco[0]?.ban_conta || ''
                });
              }}
            >
              <option defaultValue></option>
              {props.options[9]?.map((option, index) => (
                <option key={index} value={option.ban_codban}>
                  {option.ban_sglban}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Desconto</span>
          <div>
            <NumericFormat
              value={props.formDataLiquida.cnt_descon || ''}
              onValueChange={(values) => {
                const { formattedValue } = values;

                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_descon: formattedValue
                });
              }}
              onBlur={() => {
                props.setFormDataLiquida({
                  ...props.formDataLiquida,
                  cnt_valpag:
                    formatNumber(props.formDataLiquida?.cnt_valdps || 0) +
                    formatNumber(props.formDataLiquida?.cnt_juros || 0) +
                    formatNumber(props.formDataLiquida?.cnt_multa || 0) -
                    formatNumber(props.formDataLiquida?.cnt_descon || 0)
                });
              }}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Agência</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.formDataLiquida?.ban_agencia || ''}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Valor Pago</span>
          <div>
            <NumericFormat
              value={props.formDataLiquida?.cnt_valpag}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
              disabled
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Conta Corrente</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.formDataLiquida?.ban_conta || ''}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Câmbio</span>
          <div>
            <select
              className="form-select form-select-sm border border-dark"
              value={props.formDataLiquida?.cnt_codmoe || ''}
              onChange={(e) => {
                valueDol(e.target.value);
              }}
            >
              <option defaultValue></option>
              {props.options[14]?.map((option, index) => (
                <option key={index} value={option.moe_codigo}>
                  {option.moe_descri}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Data Pagamento</span>
          <div>
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={
                (props.formDataLiquida?.cnt_datpag?.slice(0, 10) !==
                  '1899-12-30' &&
                  props.formDataLiquida?.cnt_datpag) ||
                dateAtual
              }
              name="cnt_datpag"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-sm-6 col-md-2 col-lg-2 mt-4">
          <span className="col-form-label"></span>
          <div>
            <NumericFormat
              value={props.formDataLiquida?.cnt_valmoe}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              prefix=""
              className="form-control form-control-sm border border-dark"
              style={{ textAlign: 'right' }}
              suffix={
                props.value % 1 === 0
                  ? ',00'
                  : (props.value * 10) % 1 === 0
                  ? '0'
                  : ''
              }
              disabled
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-2 col-lg-2">
          <span className="col-form-label">Data Baixa</span>
          <div>
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={props.formDataLiquida?.cnt_datbai || dateAtual}
              name="cnt_datbai"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-1">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <span className="col-form-label">Obeservação</span>
          <div>
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.formDataLiquida?.cnt_observ || ''}
              name="cnt_observ"
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
