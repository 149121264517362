import { useEffect, useState } from 'react';

import { getStartAndEndDates } from '../../../../functions/getStartAndEndDates';
import { TabelaTipoDataValor, ChartPieQt } from '../../graficos';
import { FiltroDropdown } from '../FiltroDropdown';
import { FiltrosDate } from '../FiltrosDate';
import {
  fetchDataDropdown,
  fetchDataTabelaTipoDataValor,
  fetchDataGraficoPizza
} from './functions';
import { formatRequestArray } from '../../../../functions/formatRequestArray';

export const AnaliseReceitaDespesa = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataPizzaR, setPizzaR] = useState([]);
  const [dataPizzaP, setPizzaP] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [centroCusto, setCentroCusto] = useState('');
  const [natureza, setNatureza] = useState('');
  const [tipoPeriodo, setTipoPeriodo] = useState('pagamento');
  const [fonteRecurso, setFonteRecurso] = useState('');
  const handleSetTipoPeriodo = (selectedOption) => {
    setTipoPeriodo(selectedOption.value);
  };
  const [realizado, setRealizado] = useState('');

  const { startDate: sdate, endDate: edate } = getStartAndEndDates(3);

  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);

  useEffect(() => {
    let realizada = formatRequestArray(realizado);
    const fetch = async () => {
      const response1 = await fetchDataTabelaTipoDataValor(
        centroCusto,
        fonteRecurso,
        natureza,
        startDate,
        endDate,
        tipoPeriodo,
        realizada
      );

      const response2 = await fetchDataGraficoPizza(
        centroCusto,
        fonteRecurso,
        natureza,
        startDate,
        endDate,
        'R',
        tipoPeriodo,
        'centrocusto',
        realizada
      );
      const response3 = await fetchDataGraficoPizza(
        centroCusto,
        fonteRecurso,
        natureza,
        startDate,
        endDate,
        'P',
        tipoPeriodo,
        'centrocusto',
        realizada
      );

      setDataTable(response1.data.grafico);
      setPizzaR(response2.data.grafico);
      setPizzaP(response3.data.grafico);
    };
    fetch();
  }, [
    centroCusto,
    fonteRecurso,
    natureza,
    startDate,
    endDate,
    tipoPeriodo,
    realizado
  ]);

  useEffect(() => {
    const fetch = async () => {
      const response4 = await fetchDataDropdown();
      setDataDropdown(response4.data.grafico);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Análise Natureza Despesa e Fonte Recurso</h4>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltrosDate
          title1={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Inicial'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Inicial'
              : 'Data Mov Incial'
          }
          title2={
            tipoPeriodo === 'vencimento'
              ? 'Data Venc Final'
              : tipoPeriodo === 'pagamento'
              ? 'Data Pag Final'
              : 'Data Mov Final'
          }
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <FiltroDropdown
          title="Centro de Custo"
          listaItems={dataDropdown.centroCusto}
          value={centroCusto}
          setValue={setCentroCusto}
        />
        <FiltroDropdown
          title="Fonte de Recurso"
          listaItems={dataDropdown.fonteRecurso}
          value={fonteRecurso}
          setValue={setFonteRecurso}
        />
        <FiltroDropdown
          title="Natureza de Despesa"
          listaItems={dataDropdown.natureza}
          value={natureza}
          setValue={setNatureza}
        />
        <FiltroDropdown
          title="Realizado"
          listaItems={[{ item: 'A realizar' }, { item: 'Realizada' }]}
          value={realizado}
          setValue={setRealizado}
        />
        <FiltroDropdown
          title="Tipo Periodo"
          listaItems={[
            { item: 'pagamento' },
            { item: 'vencimento' },
            { item: 'movimento(competência)' }
          ]}
          value={tipoPeriodo}
          setValue={handleSetTipoPeriodo}
          isDate={true}
        />
      </div>
      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-lg-row justify-content-center text-center w-100 h-100">
          <TabelaTipoDataValor
            data={dataTable}
            title={'Receita e Despesa Mês'}
            isHeight={true}
          />
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <ChartPieQt
              data={dataPizzaR}
              titulo={'Gráfico Receita'}
              isMonetary={true}
            />
            <ChartPieQt
              data={dataPizzaP}
              titulo={'Gráfico Despesa'}
              isMonetary={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
