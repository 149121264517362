import { useState, useRef } from 'react';

import { ReturnButton } from '../../components/ReturnButton';

import { ApiDelete } from '../../services/apiService';
import { CargaLancamentos } from './carga/CargaLancamentos';
import { ConfirmationModal } from './carga/ConfirmationModal';
import { Lancamento } from './lancamento/Lancamento';

import Swal from 'sweetalert2';

export const Manutencao = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabInsert, setTabInsert] = useState(0);
  const [tabUpdate, setTabUpdate] = useState(0);
  const [tabReturn, setTabReturn] = useState(0);
  const [nvAcesso, setNvAcesso] = useState('');
  const [title, setTitle] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const fileUpload = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileUpload = async () => {
    const file = fileUpload.current.files[0];
    if (file) {
      setIsModalOpen(true);
    } else {
      setUploadMessage('Please select a file to upload.');
    }
  };

  const handleConfirmUpload = async () => {
    setIsModalOpen(false);
    const file = fileUpload.current.files[0];
    const carga = await CargaLancamentos({
      file: file /* , setResult: setResult  */
    });
    if (carga === true) {
      setUploadMessage('Arquivo enviado com sucesso.');
    } else {
      setUploadMessage('Erro ao enviar arquivo.');
    }
  };

  const LimparTabela = async () => {
    Swal.fire({
      title: 'Corrigir',
      html: 'Deseja limpar a tabela?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ApiDelete('/tabela/contabil/todos', {});
        if (response.data?.mensagem?.msg.slice(0, 3) != '100')
          alert(response.data?.mensagem?.msg);
        if (response.data?.evento === '100') {
          Swal.fire({
            title: 'Tabela limpada com suceso!',
            icon: 'success',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          });
        }
      }
    });
  };

  const handleReset = () => {
    if (tabReturn > 0) {
      setTabReturn(0);
    } else {
      setTitle('');
      setActiveTab(0);
    }
    setTabInsert(0);
    setUploadMessage('');
  };

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Manutencao"
      tabIndex="-1"
      aria-labelledby="lblManutencao"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblManutencao"
              style={{ fontSize: props.tamTitulo }}
            >
              Manutenção {title && `${title}`}
            </h1>
            {activeTab === 0 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          <div className="modal-body">
            {uploadMessage && (
              <div className="alert alert-info">{uploadMessage}</div>
            )}
            <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
              <p style={{ display: 'none' }}>Lançamento Automático</p>
            </div>

            {activeTab === 0 && (
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div
                    className="col-sm-12 col-md-12 col-lg-4 mb-1"
                    onClick={() => {
                      setTitle('de Lançamento Automático');
                      setActiveTab(1);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Lançamento Automático
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-12 col-lg-4 mb-1"
                    onClick={() => {
                      setTitle('de Carregar Documento Lançamento');
                      setActiveTab(2);
                    }}
                  >
                    <div className="card" style={{ cursor: 'pointer' }}>
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4
                          style={{
                            fontSize: props.tamSubTitulo
                          }}
                        >
                          Carregar Documento Lançamento
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                id="tab1"
              >
                {activeTab === 1 && (
                  <Lancamento
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    tabInsert={tabInsert}
                    setTabReturn={setTabReturn}
                    tabReturn={tabReturn}
                    tabUpdate={tabUpdate}
                    setTabUpdate={setTabUpdate}
                    setNvAcesso={setNvAcesso}
                  />
                )}
              </div>
              <div
                className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                id="tab2"
              >
                {activeTab === 2 && (
                  <div className="d-flex flex-column gap-2">
                    <input
                      type="file"
                      id="fileUpload"
                      name="fileUpload"
                      ref={fileUpload}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      style={{ width: 'fit-content' }}
                      onClick={LimparTabela}
                    >
                      Limpar Tabela
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            {activeTab > 0 && (
              <>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (tabReturn > 0) {
                      setTabReturn(0);
                    } else {
                      setTitle('');
                      setActiveTab(0);
                    }
                    setTabInsert(0);
                    setUploadMessage('');
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button> */}
                <ReturnButton onClick={handleReset} fontSize={props.tamTexto} />

                {tabReturn === 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (activeTab === 2) {
                        handleFileUpload();
                      } else {
                        setTabInsert(activeTab);
                      }
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                )}

                {tabReturn === 1 && nvAcesso !== 'read' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setTabUpdate(activeTab);
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmUpload}
      />
    </div>
  );
};
